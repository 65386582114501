
export default {
  // ComandPage Texts
  ComandPage: {
    title: "Команда",
    podtitle: "Главная / Команда",
  },

  BoardH1: "Совет попечителей",
  BoardP1: "Председатель Попечительского совета",
  BoardP2: "Член Попечительского совета",

  ComandH1: "Наша команда",

  ComandH2: "Руководство",
  ComandH3: "Администрация",
  ComandH4: "Кафедра языков",
  ComandH5: "Кафедра гуманитарных наук и физической культуры",
  ComandH6: "Кафедра естественно - математического цикла",
  ComandH7: "Кафедра информационных технологий",
  ComandH8: "Кафедра учета и аудита",
  ComandH9: "Воспитатели",

  //Руководство
  ComandP1: "Директор",
  ComandP2: "Заместитель директора по учебно-методической работе",
  ComandP3: "Заместитель директора образовательного учреждения",
  ComandP4: "Заместитель директора по воспитательной работе",
  ComandP5: "Заместитель директора по воспитательной работе",
  ComandP6: "Заместитель директора по воспитательной работе",

  //Администрация
  ComandP7: "Заведующий по хозяйственной части",
  ComandP8: "Инспектор по кадрам, заместитель директора по связям с общественностью",
  ComandP9: "Главный бухгалтер",
  ComandP10: "Бухгалтер",
  ComandP11: "Экономист",
  ComandP12: "Методист, офис - регистратор",
  ComandP13: "Секретарь отдела образования",
  ComandP14: "Социальный педагог", 
  ComandP15: "Психолог", 
  ComandP16: "Руководитель отдела планирования карьеры", 
  ComandP17: "Медсестра", 
  ComandP18: "Библиотекарь", 
  ComandP19: "Системный администратор - программист", 
  
  //Кафедра языков
  ComandP20: "Учитель английского языка, руководитель кафедры", 
  ComandP21: "Учитель английского языка", 
  ComandP22: "Учитель английского языка", 
  ComandP23: "Учитель английского языка", 
  ComandP24: "Учитель английского языка", 
  ComandP25: "Учитель казахского языка и литературы", 
  ComandP26: "Учитель турецкого языка", 
  ComandP27: "Учитель русского языка", 
  
  //Кафедра гуманитарных наук и физической культуры
  ComandP28: "Учитель истории, руководитель кафедры",
  ComandP29: "Учитель истории",
  ComandP30: "Учитель географии",
  ComandP31: "Учитель НВП",
  ComandP32: "Учитель физкультуры",
  ComandP33: "Учитель физкультуры",

  //Кафедра естественно - математического цикла
  ComandP34: "Учитель математики, руководитель кафедры",
  ComandP35: "Учитель математики",
  ComandP36: "Учитель математики",
  ComandP37: "Учитель химии",
  ComandP38: "Учитель физики",
  ComandP39: "Учитель физики",
  ComandP40: "Учитель биологии",

  //Кафедра информационных технологий
  ComandP41: "Учитель ИТ, заместитель директора по ИТ",
  ComandP42: "Учитель ИТ, Заведующий общежития",
  ComandP43: "Учитель ИТ",
  ComandP44: "Учитель ИТ, инспектор по делам молодежи",
  ComandP45: "Учитель ИТ",
  ComandP46: "Учитель ИТ",
  ComandP47: "Учитель ИТ",
  ComandP48: "Учитель ИТ",
  ComandP49: "Учитель ИТ, Заведующий общежития",
  ComandP50: "Учитель ИТ",
  ComandP51: "Учитель ИТ",

  //Кафедра учета и аудита
  ComandP52: "Преподаватель по финансам, руководитель кафедры",
  ComandP53: "Преподаватель по бухгалтерскому учету и 1С",
  ComandP54: "Преподаватель экономики и маркетинга",
  ComandP55: "Преподаватель по учету и аудиту",
  ComandP56: "Преподаватель по менеджменту",
  ComandP57: "преподаватель бухгалтерского учета и 1С",

  //Воспитатели
  ComandP58: "Воспитатель",

  NewsH: "Новости",
  hide: "Скрыть",
  readMore: "Подробнее",
  // AsocPage Texts
  LifePage: {
    title: "Студенческая жизнь",
    podtitle: "Главная / Студенческая жизнь",
  },
  LifeP1: "Студенческая жизнь в нашем колледже ярко выражена разнообразием культурных и спортивных мероприятий. Ежегодные мероприятии и концерты создают позитивное и вдохновляющее атмосферу, позволяя студентам полностью погрузиться в культурное разнообразие. Кроме того, студенческие клубы и организации активно проводят соревнования, способствующие развитию навыков лидерства и социальной ответственности. Студенты также наслаждаются активным образом жизни благодаря спортивным турнирам и тренировкам, что создает дружественную и энергичную обстановку в нашем учебном заведении.",
  LifeP2: "Біздің колледжде ағылшын клубы,түрік клубы, пікірталас сияқты академиялық клубтар бар. Олар студенттерге тілдік дағдыларын кеңейтуге көптеген мүмкіндіктер береді. Бұл клуб студенттердің оқу тәжірибесін байыта отырып, әртүрлі тілдер мен мәдениеттерге енетін бірегей орын. Мұнда олар бір-бірімен сөйлесе алады, интеллектуалды пікірталастарға қатыса алады және қарым-қатынас дағдыларын жетілдіре алады. Тіл клубының арқасында студенттер тек аудиторияда ғана емес, одан тыс жерлерде де колледждің тілдік ортасына ену мүмкіндігіне ие. Бұл сонымен қатар Тілдер мен мәдениеттерге ортақ қызығушылық танытатын студенттер арасында тығыз достық қарым-қатынас орнатуға ықпал етеді.",
  LifeP3: "В нашем колледже существует множество спортивных клубов, предоставляющих студентам уникальные возможности. Эти клубы создают разнообразие вариантов для занятий физической активностью и спортом. Студенты могут выбрать себе подходящий клуб в зависимости от своих интересов, будь то футбол, баскетбол, теннис или фитнес. Благодаря этим клубам, студенты не только улучшают свою физическую форму, но и находят новых друзей, разделяющих их увлечения. Спортивная общность в колледже способствует не только здоровому образу жизни, но и формированию крепкой командной духа среди студенческого сообщества. Эти спортивные возможности делают наше образовательное учреждение еще более привлекательным для студентов.",
  LifeP4: "Колледж активно организует разнообразные мероприятия для развлечения студентов. Ежемесячные мероприятия включают в себя концерты, тематические вечеринки и спортивные соревнования, создавая динамичную атмосферу в учебном заведении. Такие инициативы способствуют не только расширению социального круга студентов, но и укреплению их командного духа. Колледж также организует выездные мероприятия, включая экскурсии и путешествия, предоставляя студентам возможность получить новый опыт и воспользоваться свободным временем вне учебы. Эти активности создают положительное учебное окружение, способствуя более гармоничному студенческому опыту.",
  
  LifeH1: "Академические клубы",
  LifeH2: "Спорт клубы",
  LifeH3: "Мероприятие",

  DocumentP1: "Стратегический планa развития",
  DocumentP2: "Лицензии",
  DocumentP3: "Аттестация",
  DocumentP4: "Корпоративный кодекс",
  DocumentP5: "Правила приема",
  DocumentP6: "Правила перевода и повторного зачисления",
  DocumentP7: "Этический кодекс колледжа",
  DocumentP8: "Самооценка колледжа",
  DocumentP9: "Противодействие коррупции и комплаенс сервис:",
  DocumentP10: "Cервис 1",
  DocumentP11: "Cервис 2",
  DocumentP12: "Cервис 3",
  DocumentP13: "Cервис 4",
  DocumentP14: "Положение об академическом качестве",

  // AsocPage Texts
  AssocPage: {
    title: "Ассоциация",
    podtitle: "Главная / Ассоциация",
  },
  AssocP1: "",
  AssocP2:
    "Колледж имеет ассоциацию выпускников, которая играет важную роль в поддержке и укреплении связей между бывшими студентами. Эта взаимодействующая связь между ассоциацией выпускников и колледжем способствует развитию образовательной среды и формированию успешных будущих профессионалов.",
  AssocP3:
    "Система поддержки через патреон, где выпускники могут ежемесячно оказывать финансовую помощь колледжу.",
  AssocP4: "MeetUps в разных городах",
  AssocP5: "Мероприятия, организуемые ассоциацией",
  AssocP6: "Подкасты выпускников",
  AssocP7: "Jameco Store",
  AssocP8: "30 years anniversary",
  AssocP9:
    "Ассоциация регулярно проводит мероприятия и встречи с выпускниками в различных городах, способствуя установлению контактов и обмену опытом среди выпускников.",
  AssocP10:
    "Ассоциация регулярно организует разнообразные мероприятия. Они включают в себя встречи, футбол среди мужчин, и соревнования, способствуя не только обмену опытом, но и поддерживая дружбу и сеть профессиональных контактов.",
  AssocP11:
    "Колледж недавно отметил свое 30-летие, организовав встречу для всех выпускников. В теплой и дружественной обстановке выпускники вспоминали студенческие годы, обменивались воспоминаниями и веселились, укрепляя свои связи с учебным заведением и друг с другом.",
  AsoocH1: "Ассоциация Jameco Alumni",
  AsoocH2: "MeetUps",
  AsoocH3: "Мероприятие",
  AsoocH4: "30-летний юбилей",

  // LabaratoryPage Texts
  LabPage: {
    title: "Лаборатории",
    podtitle: "Главная / Лаборатории",
  },
  LabH: "Лаборатории",
  LabP: "Лаборатория нашего учебного заведения предоставляет студентам уникальную возможность воплотить теоретические знания в практический опыт. Студенты могут расширять свой академический опыт, разрабатывать собственные проекты и глубже понимать принципы науки, работая в нашей инновационной лаборатории.",
  // CorpusPage Texts
  CorpusPage: {
    title: "Корпус",
    podtitle: "Главная / Корпус",
  },
  CorpusH: "Корпус",
  CorpusPBold: "КАМПУС JIHC",
  CorpusP2:
    "У нас созданы все условия на территории кампуса для качественного обучения",
  CorpusP3:
    "Корпус с оборудованными кабинетами и лабораториями, современной библиотекой и большим актовым залом.",
  CorpusP4:
    "Общежитие* с раздельными секторами для мальчиком (120 мест) и девочек (180 мест).",
  CorpusP5: "Просторный спортзал и футбольное поле для игр на свежем воздухе.",
  CorpusP6:
    "Столовая предоставляющая 3-х разовое горячее питание* для студентов.",
  CorpusP7: "* оплачивается отдельно и не входит в стоимость обучения",
  CorpusP:
    "находится в экологически чистом районе в центре богатого на историю древнего города Тараз",
  // CanteenPage Texts
  CantennPage: {
    title: "Столовая",
    podtitle: "Главная / Столовая",
  },
  CanteenH: "Столовая",
  CanteenP:
    "Столовая нашего университета предлагает разнообразные и вкусные блюда для студентов. Здесь вы можете насладиться балансом здорового питания и уютной обстановкой, создавая идеальное место для обедов и общения.",
  // LabaratoryPage Texts
  DorPage: {
    title: "Общежитие",
    podtitle: "Главная / Общежитие",
  },
  DorH: "Общежитие",
  DorP: "Общежитие колледжа предоставляет студентам удобное и безопасное жилье в непосредственной близости от учебных помещений, создавая благоприятные условия для полноценной учебы и социальной активности. В общежитии университета доступны, а также уютные чайные комнаты, создавая благоприятные условия для отдыха и общения студентов.",
  // LabaratoryPage Texts
  SportPage: {
    title: "Спортзал + поле",
    podtitle: "Главная / Спортзал + поле",
  },
  SportH: "Спортзал + поле",
  SportP:
    "У нас на кампусе есть современное футбольное поле, идеально подходящее для тренировок и матчей. Также у нас есть просторный спортивный зал, оборудованный технологиями, где студенты могут заниматься различными видами спорта для поддержания физической активности и здоровья.",

  // HistoryPage Texts
  FactPage: {
    title: "Важные факты",
    podtitle: "Главная / Важные факты",
  },
  Fact1H: "41",
  Fact1P: "преподаватель работают в JIHC ",
  Fact2H: "7.0",
  Fact2P: "средний балл IELTS преподавателей",
  Fact3H: "3",
  Fact3P: "срок обучения в колледже",
  Fact4H: "100",
  Fact4P: "грантов на бесплатное обучение в колледже выделяются ежегодно",
  Fact5H: "2094",
  Fact5P: "выпускников окончили наш колледж и работают по всему миру",
  Fact6H: "47",
  Fact7H: "37%",
  Fact7P: "выпускников сразу трудоустраиваются",
  Fact8H: "63%",
  Fact8P: "выпускников сразу поступают в вузы ",
  Fact9H: "394",
  Fact9P: "студентов обучаются в колледже",
  Fact10H: "270",
  Fact10P: "мест в доме студента ",
  Fact11H: "3",
  Fact11P: "основные направления обучения: Pedagogy, IT, Accounting",

  // HistoryPage Texts
  HistoryPage: {
    title: "История колледжа",
    podtitle: "Главная / История колледжа",
  },

  HistoryP1:
    "Коммунальное государственное казенное предприятие “Жамбылский инновационный высший колледж” управления образования акимата Жамбылской области было образовано в 1993 году. Колледж более известен общественности как бывший Казахско-Турецкий колледж.",
  HistoryP2:
    "В колледже дается современное профессионально-техническое образование по программе, утвержденной Министерством образования и науки Республики Казахстан.",
  HistoryP3:
    "Колледж работает в соответствии со стандартами качества образовательного и воспитательного процессов МОФ “Білім Инновация” (оператор сети лицеев Білім Инновация”) и функционирует под патронажем “SDU University”.",
  HistoryP4:
    "Все предметы естественно-математического и профессионального направлений преподаются на английском языке. Особое внимание предоставляется ценностно-ориентированному образованию.",
  HistoryP5:
    "Колледж готовит специалистов экономического и педагогического профиля, а также профессионалов в области информационных технологий.",

  // ProfessionPage Texts
  ProfessionPage: {
    title: "Специальности",
    podtitle: "Главная / Специальности",
  },
  ProfessionH1: "Програмное обеспечение ",
  ProfessionH2: "Языки обучения:",
  ProfessionH3: "Учет и аудит",
  ProfessionH4: "Языки обучения:",
  ProfessionP1:
    "Приказ министра образования и науки Республики Казахстан от 20 января 2015 года № 19. Об утверждении Правил оказания государственной услуги в сфере технического и профессионального, послесреднего образования.",
  ProfessionP2:
    "Перевод обучающихся осуществляется из одного учебного заведения в другое, в том числе с государственного образовательного заказа на государственный образовательный заказ, с одной специальности на другую, с платной основы на обучение по государственному образовательному заказу или с одной формы обучения на другую при сдаче имеющихся академических разниц результатов обучения по дисциплинам/модулям рабочих учебных планов.",
  ProfessionP3:
    "Перевод с платной основы на обучение по государственному образовательному заказу осуществляется в течение учебного года по мере освобождения мест в этом же учебном заведении.",
  ProfessionP4:
    "Колледж обучает бухгалтерскому учету и аудиту. Специальность преподается по международной программе ACCA, что открывает уникальную возможность получить сертификат ACCA. Эта программа не только предоставляет студентам теоретические знания в области бухгалтерии, но также готовит их к соответствию международным стандартам ACCA, что является важным активом для успешной карьеры в сфере финансов и бизнеса.",
  ProfessionP5:
    "Абитуриенты могут обучаться по этой специальности только на платной основе. Стоимость обучения изменяется в зависимости года поступления.",
  ProfessionP6: "Срок обучения 2 года 10 месяцев",
  ProfessionLi1: "казахский",
  ProfessionLi2: "английский",
  ProfessionLi3: "русский",
  ProfessionLi4: "турецкий",
  ProfessionBtn1: "Подробнее",

  // Translate Texts
  TranslatePage: {
    title: "Перевод",
    podtitle: "Главная / Перевод",
  },
  TranslateH1: "Правила как перевестись с другого колледжа",
  TranslateP1:
    "Приказ министра образования и науки Республики Казахстан от 20 января 2015 года № 19. Об утверждении Правил оказания государственной услуги в сфере технического и профессионального, послесреднего образования.",
  TranslateP2:
    "Перевод обучающихся осуществляется из одного учебного заведения в другое, в том числе с государственного образовательного заказа на государственный образовательный заказ, с одной специальности на другую, с платной основы на обучение по государственному образовательному заказу или с одной формы обучения на другую при сдаче имеющихся академических разниц результатов обучения по дисциплинам/модулям рабочих учебных планов.",
  TranslateP3:
    "Перевод с платной основы на обучение по государственному образовательному заказу осуществляется в течение учебного года по мере освобождения мест в этом же учебном заведении.",
  TranslateP4:
    "В случае переезда родителей или законных представителей несовершеннолетнего обучающегося на другое место жительства допускается его перевод не в каникулярный период при представлении подтверждающих документов.",

  TranslateP5:
    "В остальных случаях перевод обучающихся осуществляется в период летних и зимних каникул.",

  TranslateP6:
    "Для перевода с платной основы на обучение по государственному образовательному заказу организация образования, реализующая образовательные программы технического и профессионального, послесреднего образования, размещает информацию о наличии вакантных мест по государственному образовательному заказу на информационных стендах, официальных интернет-сайтах организации образования.",

  TranslateP7:
    "Для перевода, обучающегося с платного обучения на обучение по государственному образовательному заказу в организации образования создается коллегиальный орган с участием педагогов и представителей органов студенческого самоуправления. Решение о переводе обучающегося принимается коллегиальным органом с учетом его успеваемости.",

  // JumbotronElem Texts
  JumbotronElemPage: {
    title: "Подача документов",
    podtitle: "Подача документов",
  },
  JumbotronElemH1: "Правила приема документов",
  JumbotronElemH2: "Документы, необходимые для поступления:",
  JumbotronElemP1:
    "Приказ министра образования и науки Республики Казахстан от 18 октября 2018 года № 578 'Об утверждении Типовых правил приема на обучение в организации образования, реализующие образовательные программы технического и профессионального, послесреднего образования'.",
  JumbotronElemP2:
    "Прием заявлений лиц на обучение в колледж: на очную форму обучения: по государственному заказу в 9 классах с 25 июня по 18 августа календарного года, на платной основе с 25 июня по 25 августа.",
  JumbotronElemP3:
    "Для получения государственной услуги услугополучатель обращается в организацию образования типо (далее-услугодатель) либо в 'электронное правительство' www.egov.kz веб-портал.",
  JumbotronElemP4:
    "Документы для поступления представляются совершеннолетними лицами лично, несовершеннолетними – в присутствии законного представителя.",
  JumbotronElemLI1: "Заявление о приеме документов;",
  JumbotronElemLI2: "Оригинал документа об образовании;;",
  JumbotronElemLI3: "Фото 4шт размером 3х4 см;",
  JumbotronElemLI4:
    "Форма № 075-У, утвержденная приказом исполняющего обязанности министра здравоохранения Республики Казахстан от 30 октября 2020 года № ҚР ДСМ-175/2020' Об утверждении форм учетной документации в области здравоохранения ' (зарегистрирован в Реестре государственной регистрации нормативных правовых актов за № 21759)",
    JumbotronElemLI5: "Справки подтверждающие наличие определенного социального статуса (многодетная семья, получатель АСП и т.п.)",
  // Hero Texts

  HeroH1P1: "Качественное образование ",
  HeroH1P2: "Сознательное воспитание",
  
  // Column,
  ColumnD1P1: "Ценностно - ориентированное воспитание",
  ColumnD1P2: "образование",
  ColumnD2P1: "Обучение на 4 языках",
  ColumnD2P2: "Модульно - компетентностный подход",
  ColumnD3P1: "Обучение",
  ColumnD3P2: "на 4 языках",
  ColumnD4P1: "Опытные",
  ColumnD4P2: "учителя",
  
  // Jumbotron,
  JumbotronH1P1: "Добро пожаловать в",
  JumbotronH1P2: "Жамбылский инновационный",
  JumbotronH1P3: "высший колледж",
  JumbotronP1P1: "Добро пожаловать в наше учебное заведение, где образование воплощается в ценностях, возможностях и вдохновении. Как директор этого колледжа, я горжусь представить вам нашу уникальную образовательную среду, где каждый человек имеет возможность раскрыть свой потенциал.",
  JumbotronP1P2: "В основе наших ценностей лежит стремление к качественному образованию. Наш колледж предоставляет уникальные возможности для студентов различных областей знаний. Мы стремимся создать инновационную среду, где студенты могут обмениваться идеями, сотрудничать с опытными преподавателями и принимать участие в исследовательских проектах.",
  JumbotronP1P3: "Спасибо за ваш интерес к нашему колледжу. Мы уверены, что здесь вы найдете не только образование, но и вдохновение для достижения ваших самых амбициозных целей.",
  JumbotronP1P4: "Директор колледжа",
  JumbotronP1P5: "Айбеков Айдын Абрашович",
  // Column2,
  Column2H1: "Наши преимущества",
  Column2P1: "Ценностно - ориентированное воспитание",
  Column2P2: "Обучение на 4 языках",
  Column2P3: "Модульно - компетентностный подход",
  Column2P4: "Качественные преподаватели практики",
  Column2P5: "Занятия проводятся на базе WorldSkills Competences, стандартов АССА и 1С",
  Column2P6: "Преподаватели с лучшим зарубежным и отечественным образованием",
  // Column3,
  Column3H1: "Важные цифры",
  Column3P1: "Средний балл IELTS преподавателей",
  Column3P2: "Учителей",
  Column3P3: "Студентов",
  Column3P4: "основные направления обучения",
  //   Footer Texts
  FooterH1: "Свяжитесь с нами",
  FooterH2: "Мы в соцсетях",
  FooterL1: "Тараз, ул. Пушкина, 154",
  //   SecondNav Texts
  SecondNavP1: "Напишите нам",
  SecondNavP2: "Позвоните нам",
  //   Navbar Texts
  NavbarP1: "080003 город Тараз, улица Пушкина, 154",
  //   ThirdNav Texts

  ThirdNavNavItem0: "Главная",
  ThirdNavNavItem1: "Колледж",
  ThirdNavNavItem2: "Поступление",
  ThirdNavNavItem3: "Специальности",
  ThirdNavNavItem4: "Инфраструктура",
  ThirdNavNavItem5: "Студентам",
  ThirdNavNavItem6: "Родителям",
  ThirdNavNavItem7: "Работадателям",
  ThirdNavNavItem8: "Выпускникам",
  ThirdNavNavItem9: "Календарь",
  //   ThirdNav drop-down item text
  ThirdNavNavItem1Li1: "История колледжа",
  ThirdNavNavItem1Li2: "Важные факты",
  ThirdNavNavItem1Li3: "Документы",
  ThirdNavNavItem1Li4: "Команда",
  ThirdNavNavItem1Li5: "Структура колледжа",
  ThirdNavNavItem1Li6: "Попечительский совет",
  ThirdNavNavItem1Li7: "Объявления",

  ThirdNavNavItem2Li1: "Специальности",
  ThirdNavNavItem2Li2: "Подача документов",
  ThirdNavNavItem2Li3: "Перевод",

  ThirdNavNavItem3Li1: "Информационные системы и программное обеспечение",
  ThirdNavNavItem3Li2: "Учет и аудит",
  ThirdNavNavItem3Li3: "Педагогика-математика",

  ThirdNavNavItem4Li1: "Корпус",
  ThirdNavNavItem4Li2: "Корпус А (учебный корпус + актовый зал)",
  ThirdNavNavItem4Li3: "Корпус В + Общежитие",
  ThirdNavNavItem4Li4: "Спортзал",
  ThirdNavNavItem4Li5: "Футбольное поле",
  ThirdNavNavItem4Li6: "Беседка для барбекю",
  ThirdNavNavItem4Li7: "Столовая",
  ThirdNavNavItem4Li8: "Прачечная",
  
  ThirdNavNavItem5Li1: "Академический календарь",
  ThirdNavNavItem5Li2: "Электронный журнал",
  ThirdNavNavItem5Li3: "Практика , Карьера , Проф. ориентация",
  ThirdNavNavItem5Li6: "Комитеты",
  ThirdNavNavItem5Li7: "Студенческая жизнь",
  ThirdNavNavItem5Li8: "Дом студента",
  
  ThirdNavNavItem6Li1: "Род. комитет",
  ThirdNavNavItem6Li2: "Контакты с колледжем",
  ThirdNavNavItem6Li3: "Связь с преподавателем",
  
  ThirdNavNavItem7Li1: "Партнеры",
  ThirdNavNavItem7Li2: "Подготовка специалистов",
  
  ThirdNavNavItem8Li1: "Ассоциация",
  ThirdNavNavItem8Li2: "Спонсорство",

  ThirdNavNavItem9Li1: "Google Calendar",
  //   AcademicCalendar Texts
  AcademicCalendarInfoText: "Принимая во внимание нужды участников учебного процесса и государственной образовательной программы, годовой календарный учебный график составлен так, что учебный год будет начинаться 1 сентября и заканчиваться 28 июня.",
  AcdemicCalendarP1: "График 2022-2023 учебного года будет следующим:",
  AcdemicCalendarP2:
    "Просмотреть наш годовой календарь на 2023-2024 учебный год можно здесь",
  AcdemicCalendarLi1:
    "1-ая четверть: с 21 августа 2023 г. по 13 октября 2023 г. (8 недель)",
  AcdemicCalendarLi2:
    "2-ая четверть: с 23 октября 2023 г. по 22 декабря 2023 г. (9 недель)",
  AcdemicCalendarLi3:
    "3-яя четверть: с 8 января 2024 г. по 15 марта 2024 г. (10 недель)",
  AcdemicCalendarLi4:
    "4-ая четверть: с 25 марта 2024 г. по 31 мая 2024 г. (10 недель)",

  // Accounting Texts
  accountingPage: {
    title: "Учет и аудит",
    podtitle: "Главная / Учет и аудит",
  },

  AccountingInfoH1: "Учет и аудит",
  AccountingInfoH2: "Обучаемые предметы",
  AccountingInfoP1: "В колледже вы можете изучать бухгалтерию в рамках программы ACCA, что предоставляет уникальную возможность получить сертификат ACCA. Эта программа обеспечивает студентов не только теоретическими знаниями в области бухгалтерии, но также подготавливает их к международному стандарту ACCA, что является ценным активом в карьере в области финансов и бизнеса.",

  AccountingInfoLi1: "Углубленное изучение английского и математики",
  AccountingInfoLi2: "Обучение согласно требованиям и стандартам профессиональной сертификации АССА (Британская ассоциация сертифицированных присяжных бухгалтеров, объединяющая профессионалов в области финансов, учета и аудита)",
  AccountingInfoLi3: "Изучение 1С",
  AccountingInfoLi4: "Освоение казахстанского налогообложения ",
  AccountingInfoLi5: "Опытные и продвинутые преподаватели",
  AccountingInfoLi6: "Возможность начать стажироваться в аудиторской фирме",

  AccountingInfoCard1: "Менеджмент",
  AccountingInfoCard2: "Аудит",
  AccountingInfoCard3: "Маркетинг",
  AccountingInfoCard4: "Финанс",
  // IT Texts
  ItPage: {
    title: "Програмное обеспечение",
    podtitle: "Главная / Програмное обеспечение",
  },

  ItH1: "Програмное обеспечение",
  ItH2: "Обучаемые предметы",
  ItP1: "В колледже вы можете получить степень ИТ-специалиста, изучая различные аспекты информационных технологий, включая программирование, сетевые технологии и базы данных. Обучение будет охватывать широкий спектр тем, таких как разработка программного обеспечения, администрирование систем и управление проектами в области информационных технологий.",
  ItLi1: "Обучение на гранте",
  ItLi2: "Занятия на базе компетенции WorldSkills",
  ItLi3: "Участие в различных IT хакатонах и StartUp",
  ItLi4: "Обучение по модулям",
  ItLi5: "Опытные и продвинутые преподаватели",
  ItLi6: "Возможность начать стажироваться в IT компании",

  ItCard1: "UI/UX дизайн",
  ItCard2: "Графический дизайн",
  ItCard3: "Веб разработка",
  ItCard4: "Мобильная разработка",

    //Pedagokika-matematika
    PedogogikaMathH1: "Педагогика математика",
    PedogogikaMathH2: "Обучаемые предметы",

    PedogogikaMathP1: "В колледже вы можете получить степень математического преподавателя, изучая различные математические дисциплины, включая алгебру, геометрию, математический анализ и теорию вероятностей. После завершения обучения и получения степени, вы сможете приступить к преподаванию математики на среднем уровне.",
    PedogogikaMathP2: "Углубленное изучение английского и математики",
    PedogogikaMathP3: "Изучение высшей математики",
    PedogogikaMathP4: "Опытные и продвинутые преподаватели",
    PedogogikaMathP5: "Возможность начать стажироваться в школах",
    PedogogikaMathP6: "Высшая математика",
    PedogogikaMathP7: "Алгебра",
    PedogogikaMathP8: "Геометрия",
    PedogogikaMathP9: "Математический анализ",

    //PreperationProf
    PrepProfH1: "Электронды журнал",
    PrepProfP1: "Edupage - это неотъемлемый инструмент для современных колледжей и школ обеспечивающий эффективное взаимодействие между учителями, студентами и родителями. Этот приложение облегчает доступ к актуальной информации о расписании занятий, домашних заданиях, и обновлениях школьной жизни в режиме реального времени.",
 
      //Profession description
  ProfessionDesH1: "ОБЩАЯ СТРУКТУРА ОБРАЗОВАНИЯ В КОЛЛЕДЖЕ",
  ProfessionDesH2: "ЯЗЫКОВАЯ КАРТА ВСЕГО ПРОЦЕССА ОБУЧЕНИЯ В КОЛЛЕДЖЕ",
  ProfessionDesH3: "INFORMATION TECHNOLOGY",
  ProfessionDesH4: "МЫ ОХВАТЫВАЕМ МНОГИЕ СФЕРЫ IT",
  ProfessionDesH5: "СМОЖЕШЬ СТАТЬ",
  ProfessionDesH6: "ACCOUNTING",
  ProfessionDesH7: "TEACHER TRAINING (MATHEMATICS)",
  ProfessionDesH8: "Подробнее",

  ProfessionDesP1: "Завершение школьной программы, где точные предметы преподаются на английском языке, интенсивное освоение английского языка, углубление в математику и получение основ турецкого языка.",
  ProfessionDesP2: "Изучение профессиональных и специальных предметов на английском языке.",
  ProfessionDesP3: "Продолжение изучения профессиональных и специальных предметов на английском языке и прохождение практики на производстве для практического применения освоенных дисциплин.",
  
  ProfessionDesP4: "20% НА КАЗАХСКОМ ЯЗЫКЕ",
  ProfessionDesP5: "5% НА РУССКОМ ЯЗЫКЕ",
  ProfessionDesP6: "5% НА ТУРЕЦКОМ ЯЗЫКЕ",
  ProfessionDesP7: "70% НА АНГЛИЙСКОМ ЯЗЫКЕ",

  ProfessionDesP8: "Современные технологии сейчас везде и они ежедневно меняют мир и влияют на нашу жизнь. Комплексный и специализированный подход к IT означает, что вы получаете широкий выбор и прекрасные перспективы. Наши партнеры - это фирмы, которые имеют крепкие позиции на казахстанском рынке информационных технологий.",
  ProfessionDesP9: "Программирование (Java, Python)",
  ProfessionDesP10: "Разработка приложений (Application development)",
  ProfessionDesP11: "Графический дизайн (Graphic Design)",
  ProfessionDesP12: "Менеджмент в IT (IT Management)",
  ProfessionDesP12_1: "Web Разработка (Web Development)",


  ProfessionDesP13: "Программистом (Java, Python)",
  ProfessionDesP14: "Разработчиком сайтов (Full Stack)",
  ProfessionDesP15: "Дизайнером (Graphic, UX/UI, 3D)",
  ProfessionDesP16: "Руководителем проекта (Project Manager)",
  ProfessionDesP16_1: "Разработчиком мобильных приложении (Mobile Application)",

  ProfessionDesP17: "ПРОДОЛЖИТЕЛЬНОСТЬ ОБУЧЕНИЯ 2 года и 10 месяцев",
  ProfessionDesP18: "СТОИМОСТЬ ОБУЧЕНИЯ Грант (отбор ведется строго смотря на аттестат за 9 класс)",
  ProfessionDesP19: "СТИПЕНДИЯ И СКИДКИ для грантового отделения от 32,000 KZT ежемесячно выплачивается каждому студенту если нет оценок ниже B-",
  
  ProfessionDesP20: "Программа по бухгалтерскому учету нацелена на подготовку специалистов, обладающих теоретическими знаниями в области учета и финансов, владеющих международными стандартами финансовой отчетности, а также профессиональными компетенциями и техническими навыками, необходимыми для работы на международном и локальном рынке труда.",
  ProfessionDesP21: "Профессиональные преподаватели и практики делают нашу программу прикладной, обеспечивая высокий уровень трудоустройства выпускников. Стандарты международной сертификации АССА (Ассоциация дипломированных сертифицированных бухгалтеров) направляют нашу программу быть актуальной и емкой в полном соответствии с современными реалиями рынка труда.",
  
  ProfessionDesP22: "Бухгалтером",
  ProfessionDesP23: "Менеджером",
  ProfessionDesP24: "Аналитиком",
  ProfessionDesP25: "Финансистом",
  
  ProfessionDesP26: "СТОИМОСТЬ ОБУЧЕНИЯ 645,000 тенге за 1 академический год (платное отделение, гранты не предоставляются)",
  ProfessionDesP27: "СКИДКИ Лучшие 5 студентов в своей группе после 1 семестра получают скидки от 10 до 20% от стоимости обучения",

  ProfessionDesP28: "Программа направлена на формирование комплексной фундаментальной теоретической подготовки в педагогике и методологическом современном аппарате обучения, углубление теоретических и практических знаний студентов по педагогике и психологии, методологии, интеграции с другими отраслями науки, освоение современных концептов обучения математики и методах и формах научного познания. Обязательным является скрупулезное изучение математической науки для углубленного понимания и знания предмета математики.",
  ProfessionDesP29: "Подготовка педагогических кадров с английским языком обучения, способных осуществлять и оптимизировать профессиональную деятельность в условиях трехъязычия в соответствии с технологией интегрированного предметно-языкового обучения, ценностно-ориентированного воспитания и современным методам обучения математики. ПРОДОЛЖИТЕЛЬНОСТЬ ОБУЧЕНИЯ 3 года и 10 месяцев",

  ProfessionDesP30: "ПРОДОЛЖИТЕЛЬНОСТЬ ОБУЧЕНИЯ 3 года и 10 месяцев",
  ProfessionDesP31: "СТОИМОСТЬ ОБУЧЕНИЯ Грант (отбор ведется строго смотря на аттестат за 9 класс)",
  ProfessionDesP32: "СТИПЕНДИЯ И СКИДКИ для грантового отделения от 32,000 KZT ежемесячно выплачивается каждому студенту если нет оценок ниже B-",

  StuLifeP1: "Студенческая жизнь в нашем колледже ярко выражена разнообразием культурных и спортивных мероприятий и четко направлена на соответствие системе ценностно - ориентированному образованию - QUNDYLYK.",
  StuLifeP2: "Ежегодные мероприятия и концерты создают позитивное и вдохновляющее атмосферу, позволяя студентам полностью погрузиться в культурное разнообразие и создает дружественную и энергичную обстановку в нашем учебном заведении.",
  StuLifeP3: "Кроме того, студенческие клубы и организации активно проводят соревнования, способствующие развитию навыков лидерства и социальной ответственности.",
  StuLifeP4: "Координаторами студенческой жизни являются руководители комитетов по делам молодежи",
  StuLifeP5: "Координатор по студенческий жизни мальчиков. Email:",
  StuLifeP6: "Координатор по студенческий жизни девочек. Email:",
  
  Stuhouse1: "Колледж предоставляет каждому студенту возможность подать заявку на заселение в Дом студента (общежитие) на территории кампуса.",
  Stuhouse2: "Проживание в общежитии строго рекомендуется всем студентам в связи с очень высокой образовательной нагрузкой и сложностью обучения, важно знать что для студентов 1 курса проживание в Доме студента обязательно",
  Stuhouse3: "Студенты обязаны соблюдать правила внутреннего распорядка Дома студента.",
  Stuhouse4: "ПРАВИЛА",

  Sponship1: "Ассоциация выпускников имеет официальную платформу для регулярной аккумуляции финансовых средств с целью развития деятельности ассоциации и реализации социальных проектов в колледже.",
  Sponship2: "Каждый выпускник может внести свой посильный вклад в свой любимый колледж",
  Sponship3: "Руководство по регистрации в патреон",
  Sponship4: "Зарегистрируйтесь в Patreon, чтобы внести свой вклад в колледж, отсканировав QR-код",

  CareerH1: "В колледже действует центр профессиональной ориентации и планирования карьеры",
  CareerH2: "Этап 1 — Самоанализ",
  CareerH3: "Этап 2 — Действие",
  CareerH4: "Этап 3 — Обратная связь",

  CareerP1: "Мы начинаем помогать студентам правильно выбрать дальнейшую траекторию развития, спланировать поступление в вуз или карьеру и начать искать работу в период их обучения при достаточном высоком уровне подготовки. Сначала идёт этап подготовки, а потом мы вместе выходим в реальный мир и ищем правильную траекторию.",
  CareerP2: "Благодаря нашей программе студенты и выпускники готовятся к уверенному выходу на рынок труда или поступлению в вуз мечты. Для этого мы много работаем над целеполаганием, вместе анализируем студента и его/ее личность, помогаем осознать собственные точки роста и уже после выбираем правильный путь подготовки к поступлению в вуз или выбираем трек по выходу на рынок труда",
  CareerP3: "На данном этапе студенты и выпускники начинают самостоятельно применять знания, полученные на первом этапе программы. Студент сам создает программу подготовки к поступлению в вуз, начинает готовиться к необходимым экзаменам и изучает способ подачи документов в вуз, либо начинает создавать свое портфолио и выполняет задания, через участие в необходимых проектах, создание резюме и сопроводительных писем, обучению презентовать себя на интервью, анализирование рынок труда и самое главное правильного выбора места практики с цельй дальнейшего трудоустройства.",
  CareerP4: "После собеседований обсуждаем результат проделанной работы. Делаем разбор ошибок и усиливаем сильные стороны.",
  CareerP5: "Руководитель центра - Джанаев Газизбек",

  ParComit1: "Родительский комитет — это важный орган, который играет важную роль в жизни колледжа. Он представляет интересы родителей студентов и работает в тесном сотрудничестве с администрацией учебного заведения.",  
  ParComit2: "Задача родительского комитета — содействовать развитию колледжа и обеспечению лучших условий для обучения студентов. Члены родительского комитета активно вовлечены в жизнь колледжа, проводят собрания и консультации для родителей студентов, сотрудничают с педагогическим составом и администрацией.",  
  ParComit3: "Родительский комитет также активно занимается социальным благотворительным деятельностью. Он оказывает поддержку финансовую и организационную поддержку нуждающимся студентам, организует культурно-досуговые мероприятия, способствует развитию сотрудничества с другими образовательными организациями. Родительский комитет призван обеспечить благоприятную и разнообразную образовательную среду для студентов, позволяющую им развивать свои таланты и потенциалы.",  
  ParComit4: "Каждая группа выбирает 1 члена из числа родителей в комитет в сентябре месяце каждого учебного года. Члены родительского комитета выбирают председателя родительского комитета сроком на 1 учебный год.",  
  ParComit5: "Родительский комитет тесно работает с заместителями директора по работе с родителями",  

  ConTeacherP1: "Взаимодействие с преподавателями в основном реализуется через официальные каналы связи с родителями и студентами - Edupage (электронный портал в колледже). Каждый родитель обязан зарегистрироваться в Edupage и установить приложение на свой смартфон. Все вопросы и обращения к преподавателям должны отправляться через Edupage. Преподаватели также взаимодействуют с родителями черезе Edupage.",
  ConTeacherP2: "Для обеспечения эффективных оффлайн встреч родителей с преподавателями (лицом к лицу) каждый семестр организуются “Встречи родителей с преподавателями”. На данных встречах каждый родитель и преподаватель имеет возможность обсудить образовательные аспекты индивидуально.",
  ConTeacherP3: "При необходимости внепланово встретиться с преподавателем необходимо заранее обратиться к преподавателю через Edupage, преподаватель обязательно ответит и предложит несколько вариантов по времени и дате встречи, родитель может выбрать подходящее время и придя в колледж встретиться с преподавателем.",
  ConTeacherP4: "Родителям не разрешается участвовать на уроках.",

  WorkP1: "JIHC является государственным колледжем входящим в сеть образовательных учреждений находящихся под руководством международного общественного фонда «БІЛІМ-ИННОВАЦИЯ» и патронаже «SDU University»",
  WorkP2: "Благодаря инновационным подходам, политике англоязычного образования и ценностно-ориентированного воспитания JIHC с 1993 года является флагманом профессионально - технического образования в стране.",
  WorkP3: "Колледж открыт к тесному взаимодействию с работодателями через создание актуальных учебных программ в целях подготовки востребованных специалистов под нужды партнеров.",
  WorkP4: "На данный момент колледж тесно сотрудничает со следующими партнерами",
  WorkP5: "Мы открыты к сотрудничеству с организациями заинтересованными в наращивании своего кадрового потенциала",
  WorkP6: "Руководитель центра - Джанаев Газизбек",

  Calendar: "В данном разделе вы видите все важные ивенты в колледже отображаемые через Google Calendar",

  Edupage: "Edupage - это неотъемлемый инструмент для современных колледжей и школ обеспечивающий эффективное взаимодействие между учителями, студентами и родителями. Этот приложение облегчает доступ к актуальной информации о расписании занятий, домашних заданиях, и обновлениях школьной жизни в режиме реального времени.",

  ContactP1: "Концепция ценностно - ориентированного образования имеет важное условие тесного взаимодействия между колледжем и родителями студентов. Воспитательный и образовательные процессы должны вестись в тесной координации между педагогами и родителями. Для налаживания взаимодействия и полного понимания происходящих процессов реализуемых колледжем родителями системно проходят следующие мероприятия:",
  ContactP2: "Родительские собрания 1 раз в семестр под руководством кураторов группы и воспитателей",
  ContactP3: "Приглашение каждого родителя на воспитательный час в течение учебного года",
  ContactP4: "Организация спортивных и культурных мероприятий с активным участием родителей (Наурыз, Этические вечера и т.п.)",
  ContactP5: "Посещение семьи студента 1 раз в течении 1 курса",
  ContactP6: "Приглашение родителя в колледж в течении последнего года обучения в колледже для утверждения плана поступления в вуз и/или планирования карьеры",
  ContactP7: "Организация “Встреч родителей и преподавателей” для анализа успеваемости студента 1 раз в семестр",
  ContactP8: "Приглашение родителя на заседания комитета академичсекого качества и/или дисциплинарного совета при необходимости",
  ContactP9: "Организация кружков для родителей по интересам",
  ContactP10: "Реализация плана работ “Академии Мам” и “Совета Отцов”",
  ContactP11: "Работа с родителями координируется заместителями директора по работе с родителями",
};
