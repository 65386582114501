

export default {
  // DocumentPage Texts
  DocumentPage: {
    title: "Лицензиялар және аккредиттеу",
    podtitle: "Басты бет / Лицензиялар және аккредиттеу",
  },
  // BoardPage Texts
  BoardPage: {
    title: "Қамқоршылар кеңесі",
    podtitle: "Басты бет / Қамқоршылар кеңесі",
  },
  BoardH1: "Қамқоршылар кеңесі",
  BoardP1: "Қамқоршылық кеңесінің төрағасы",
  BoardP2: "Қамқоршылық кеңесінің мүшесі",
  // ComandPage Texts
  ComandPage: {
    title: "Ұйым",
    podtitle: "Басты бет / Ұйым",
  },
  
  ComandH1: "Біздің Ұйым",

  ComandH2: "Басшылық",
  ComandH3: "Әкімшілік",
  ComandH4: "Тілдер кафедрасы",
  ComandH5: "Гуманитарлық ғылымдар және дене шынықтыру кафедрасы",
  ComandH6: "Жаратылыстану-математикалық цикл кафедрасы",
  ComandH7: "Ақпараттық технологиялар кафедрасы",
  ComandH8: "Бухгалтерлік есеп және аудит кафедрасы",
  ComandH9: "Тәрбиешілер",

  //Басшылық
  ComandP1: "Директор",
  ComandP2: "Директордың оқу-әдістемелік жұмысы жөніндегі орынбасары",
  ComandP3: "Директордың оқу-ісі жөніндегі орынбасары",
  ComandP4: "Директордың тәрбие ісі жөніндегі орынбасары",
  ComandP5: "Директордың тәрбие ісі жөніндегі орынбасары",
  ComandP6: "Директордың тәрбие ісі жөніндегі орынбасары",

  //Әкімшілік
  ComandP7: "Шаруашылық меңгерушісі",
  ComandP8: "Кадр инспекторы, директордың қоғаммен байланыс жөніндегі орынбасары",
  ComandP9: " Бас есепші",
  ComandP10: "Есепші",
  ComandP11: "Экономист", 
  ComandP12: "Әдіскер, кеңсе тіркеушісі",
  ComandP13: "Оқу бөлімінің хатшысы",
  ComandP14: "Әлеуметтік педагог", 
  ComandP15: "Психолог", 
  ComandP16: "Карьера жобалау бөлімінің басшысы", 
  ComandP17: "Медбике", 
  ComandP18: "Кітапханашы", 
  ComandP19: "Жүйелік администратор – программист", 
  
  //Тілдер кафедрасы
  ComandP20: "Ағылшын тілі пәні оқытушысы, кафедра меңгерушісі", 
  ComandP21: "Ағылшын тілі пәні оқытушысы", 
  ComandP22: "Ағылшын тілі пәні оқытушысы", 
  ComandP23: "Ағылшын тілі пәні оқытушысы", 
  ComandP24: "Ағылшын тілі пәні оқытушысы", 
  ComandP25: "Қазақ тілі мен әдебиеті  пәні оқытушысы", 
  ComandP26: "Түрік тілі пәні оқытушысы", 
  ComandP27: "Орыс тілі пәні оқытушысы", 
  
  //Гуманитарлық ғылымдар және дене шынықтыру кафедрасы
  ComandP28: "Тарих пәні оқытушысы, кафедра меңгерушісі",
  ComandP29: "Тарих пәні оқытушысы",
  ComandP30: "География пәні оқытушысы",
  ComandP31: "АӘД пәні оқытушысы",
  ComandP32: "Дене шынықтыру пәні оқытушысы",
  ComandP33: "Дене шынықтыру пәні оқытушысы",

  //Жаратылыстану-математикалық цикл кафедрасы
  ComandP34: "Математика пәні оқытушысы, кафедра меңгерушісі",
  ComandP35: "Математика пәні оқытушысы",
  ComandP36: "Математика пәні оқытушысы",
  ComandP37: "Химия пәні оқытушысы",
  ComandP38: "Физика пәні оқытушысы",
  ComandP39: "Физика пәні оқытушысы",
  ComandP40: "Биология пәні оқытушысы",

  //Ақпараттық технологиялар кафедрасы
  ComandP41: "Ақпараттық жүйелер пәні оқытушысы, директордың ақпараттық жүйелер жөніндегі орынбасары",
  ComandP42: "Ақпараттық жүйелер пәні оқытушысы, жатақхана меңгерушісі",
  ComandP43: "Ақпараттық жүйелер пәні оқытушысы",
  ComandP44: "Ақпараттық жүйелер пәні оқытушысы, жастар ісі жөніндегі инспектор",
  ComandP45: "Ақпараттық жүйелер пәні оқытушысы",
  ComandP46: "Ақпараттық жүйелер пәні оқытушысы",
  ComandP47: "Ақпараттық жүйелер пәні оқытушысы",
  ComandP48: "Ақпараттық жүйелер пәні оқытушысы",
  ComandP49: "Ақпараттық жүйелер пәні оқытушысы, жатақхана меңгерушісі",
  ComandP50: "Ақпараттық жүйелер пәні оқытушысы",
  ComandP51: "Ақпараттық жүйелер пәні оқытушысы",

  //Бухгалтерлік есеп және аудит кафедрасы
  ComandP52: "Қаржы оқытушысы, кафедра меңгерушісі",
  ComandP53: "Бухгалтерлік есеп және 1С оқытушысы",
  ComandP54: "Экономика және маркетинг пәні оқытушысы",
  ComandP55: "Есеп және аудит пәні оқытушысы",
  ComandP56: "Менеджмент пәні оқытушысы",
  ComandP57: "преподаватель бухгалтерского учета и 1С",

  //Тәрбиешілер
  ComandP58: "Тәрбиеші",

  NewsH: "Жаңалықтар",
  hide: "Жасыру",
  readMore: "Толығырақ",
  // AsocPage Texts
  LifePage: {
    title: "Студенттік өмір",
    podtitle: "Басты бет / Студенттік өмір",
  },
  LifeP1: "Біздің колледждегі студенттік өмір мәдени және спорттық іс-шаралардың алуан түрлілігімен айқын көрінеді. Жыл сайынғы іс-шаралар мен концерттер студенттерге мәдени әртүрлілікке толығымен енуге мүмкіндік беретін жағымды және шабыттандыратын атмосфера жасайды. Сонымен қатар, студенттік клубтар мен ұйымдар көшбасшылық пен әлеуметтік жауапкершілік дағдыларын дамытуға ықпал ететін жарыстарды белсенді түрде өткізеді. Студенттер сонымен қатар спорттық турнирлер мен жаттығулар арқылы белсенді өмір салтын ұнатады, бұл біздің оқу орнымызда достық және жігерлі жағдай жасайды.",
  LifeP2: "Біздің колледжде ағылшын клубы,түрік клубы, пікірталас сияқты академиялық клубтар бар. Олар студенттерге тілдік дағдыларын кеңейтуге көптеген мүмкіндіктер береді. Бұл клуб студенттердің оқу тәжірибесін байыта отырып, әртүрлі тілдер мен мәдениеттерге енетін бірегей орын. Мұнда олар бір-бірімен сөйлесе алады, интеллектуалды пікірталастарға қатыса алады және қарым-қатынас дағдыларын жетілдіре алады. Тіл клубының арқасында студенттер тек аудиторияда ғана емес, одан тыс жерлерде де колледждің тілдік ортасына ену мүмкіндігіне ие. Бұл сонымен қатар Тілдер мен мәдениеттерге ортақ қызығушылық танытатын студенттер арасында тығыз достық қарым-қатынас орнатуға ықпал етеді.",
  LifeP3: "Біздің колледжде студенттерге бірегей мүмкіндіктер беретін көптеген спорт клубтары бар. Бұл клубтар физикалық белсенділік пен спортпен шұғылданудың әртүрлі нұсқаларын жасайды. Студенттер футбол, баскетбол, теннис немесе фитнес болсын, өз мүдделеріне сәйкес клубты таңдай алады. Осы клубтардың арқасында студенттер өздерінің фитнестерін жақсартып қана қоймай, олардың құмарлықтарымен бөлісетін жаңа достар табады. Колледждегі спорттық қауымдастық салауатты өмір салтын ғана емес, сонымен қатар студенттер қауымдастығы арасында мықты командалық рухты қалыптастыруға ықпал етеді. Бұл спорттық мүмкіндіктер біздің оқу орнымызды студенттер үшін одан да тартымды етеді.",
  LifeP4: "Колледж студенттердің көңілін көтеру үшін түрлі іс-шараларды белсенді ұйымдастырады. Ай сайынғы іс-шараларға концерттер, тақырыптық кештер және спорттық жарыстар кіреді, бұл оқу орнында динамикалық атмосфера жасайды. Мұндай бастамалар студенттердің әлеуметтік шеңберін кеңейтуге ғана емес, олардың командалық рухын нығайтуға да ықпал етеді. Колледж сонымен қатар экскурсиялар мен саяхаттарды қоса, көшпелі іс-шараларды ұйымдастырады, бұл студенттерге жаңа тәжірибе алуға және оқудан тыс бос уақытты пайдалануға мүмкіндік береді. Бұл әрекеттер студенттердің үйлесімді тәжірибесін дамыта отырып, оң оқу ортасын жасайды.",

  LifeH1: "Академиялық үйірмелер",
  LifeH2: "Спорт үйірмелері",
  LifeH3: "Іс-шаралар",

  DocumentP1: "Дамудың стратегиялық жоспары",
  DocumentP2: "Лицензиялар",
  DocumentP3: "Аттестация",
  DocumentP4: "Корпоративтік кодекс",
  DocumentP5: "Қабылдау ережелері",
  DocumentP6: "Ауысу және қайта қабылдау ережелері",
  DocumentP7: "Колледждің этикалық кодексі",
  DocumentP8: "Колледждің өзін-өзі бағалауы",
  DocumentP9: "Сыбайлас жемқорлыққа қарсы және комплаенс қызметі:",
  DocumentP10: "1 қызмет",
  DocumentP11: "2 қызмет",
  DocumentP12: "3 қызмет",
  DocumentP13: "4 қызмет",
  DocumentP14: "Академиялық сапа ережелері",

  // AsocPage Texts
  AssocPage: {
    title: "Ассоциация",
    podtitle: "Басты бет / Ассоциация",
  },
  AssocP1: "",
  AssocP2:
    "Колледжде бұрынғы студенттер арасындағы байланысты қолдау мен нығайтуда маңызды рөл атқаратын түлектер қауымдастығы бар. Түлектер қауымдастығы мен колледж арасындағы бұл өзара байланыс білім беру ортасын дамытуға және табысты болашақ мамандарды қалыптастыруға ықпал етеді.",
  AssocP3:
    "Түлектер патреон жүйесі арқылы ай сайын колледжге қаржылық көмек көрсете алады.",
  AssocP4: "Әртүрлі қалалардағы MeetUps",
  AssocP5: "Қауымдастық ұйымдастыратын іс-шаралар",
  AssocP6: "Түлектер подкасттары",
  AssocP7: "Jameco Store",
  AssocP8: "30 жылдық",
  AssocP9:
    "Қауымдастық әр түрлі қалаларда түлектермен үнемі іс-шаралар мен кездесулер өткізеді, бұл түлектер арасында байланыс орнатуға және тәжірибе алмасуға ықпал етеді.",
  AssocP10:
    "Қауымдастық үнемі түрлі іс-шаралар ұйымдастырады. Бұл іс-шаралар кездесулер, ерлер арасындағы футбол, және тәжірибе алмасуды ғана емес, сонымен қатар достық пен кәсіби байланыстар желісін қолдайтын жарыстарды да мақсат етеді.",
  AssocP11:
    "Жақында Колледж өзінің 30 жылдық мерейтойын атап өтіп, барлық түлектерге кездесу ұйымдастырды. Жылы және достық жағдайда түлектер студенттік жылдарды еске алып, естеліктерімен алмасып, көңіл көтеріп, оқу орнымен және бір-бірімен байланыстарын нығайтты.",
  AsoocH1: "Jameco Alumni қауымдастығы",
  AsoocH2: "MeetUps",
  AsoocH3: "Мероприятие",
  AsoocH4: "30-летний юбилей",
  // LabaratoryPage Texts
  LabPage: {
    title: "Лабораториялар",
    podtitle: "Басты бет / Лабораториялар",
  },
  LabH: "Лабораториялар",
  LabP: "Біздің оқу орнымыздың зертханасы студенттерге теориялық білімді практикалық тәжірибеге енгізудің бірегей мүмкіндігін ұсынады. Студенттер біздің инновациялық зертханада жұмыс істей отырып, академиялық тәжірибелерін кеңейте алады, өз жобаларын жасай алады және ғылым принциптерін тереңірек түсіне алады.",
  // CorpusPage Texts
  CorpusPage: {
    title: "Корпус",
    podtitle: "Басты бет / Корпус",
  },
  CorpusH: "Корпус",
  CorpusPBold: "КАМПУС JIHC",
  CorpusP:
    "Біздің корпус – студенттер білім мен әлеуметтік белсенділіктің тамаша үйлесімін табатын шабыттандыратын орын. Біздің корпус пен аудиториямызда заманауи білім беру тәжірибесіне ықпал ететін инновациялық дизайн бар.",
    CorpusP2:
    "Біз студенттер қалашығында сапалы білім алу үшін барлық жағдайды жасадық",
  CorpusP3:
    "Жабдықталған кабинеттері мен зертханалары, заманауи кітапханасы мен үлкен акт залы бар ғимарат.",
  CorpusP4:
    "Ұлдар (120 орын) және қыздар (180 орын) үшін жеке бөлімдері бар жатақхана*.",
  CorpusP5: "Ашық ойындарға арналған кең спорт залы мен футбол алаңы.",
  CorpusP6:
    "Оқушыларды күніне 3 мезгіл ыстық тамақпен қамтамасыз ететін асхана*.",
  CorpusP7: "* бөлек төленеді және оқу құнына қосылмайды",
    // CanteenPage Texts
  CantennPage: {
    title: "Асхана",
    podtitle: "Басты бет / Асхана",
  },
  CanteenH: "Асхана",
  CanteenP:
    "Біздің университеттің асханасы студенттерге әртүрлі және дәмді тағамдар ұсынады. Мұнда Сіз дұрыс тамақтану мен жайлы ортаның тепе-теңдігін тамашалай аласыз, бұл түскі ас пен әлеуметтену үшін тамаша орын жасайды.",
  // LabaratoryPage Texts
  DorPage: {
    title: "Жатақхана",
    podtitle: "Басты бет / Жатақхана",
  },
  DorH: "Жатақхана",
  DorP: "Колледж жатақханасы студенттерге оқу үй-жайларына жақын жерде ыңғайлы және қауіпсіз баспана беріп, толыққанды оқу және әлеуметтік белсенділік үшін қолайлы жағдай жасайды. Университеттің жатақханасында студенттердің демалуына және қарым-қатынасына қолайлы жағдай жасай отырып, жайлы шай бөлмелері де бар.",
  // LabaratoryPage Texts
  SportPage: {
    title: "Спортзал + алаң",
    podtitle: "Басты бет / Спортзал + алаң",
  },
  SportH: "Спортзал + алаң",
  SportP:
    "Біздің кампуста жаттығулар мен матчтарға өте ыңғайлы заманауи футбол алаңы бар. Сондай-ақ бізде студенттер физикалық белсенділік пен денсаулықты сақтау үшін әртүрлі спорт түрлерімен айналыса алатын технологиямен жабдықталған кең спорт залы бар.",

  // FactPage Texts
  FactPage: {
    title: "Маңызды фактілер",
    podtitle: "Басты бет / Маңызды фактілер",
  },
  Fact1H: "41",
  Fact1P: "оқытушы JIHC-де жұмыс істейді ",
  Fact2H: "7.0",
  Fact2P: "IELTS оқытушыларының орташа балы",
  Fact3H: "3",
  Fact3P: "колледжде оқу мерзімі",
  Fact4H: "100",
  Fact4P: "колледжде тегін оқу гранттары жыл сайын бөлінеді",
  Fact5H: "2094",
  Fact5P:
    "түлектер біздің колледжді бітіріп, әлемнің түкпір-түкпірінде жұмыс істейді",
  Fact6H: "47",
  Fact6P: "түлектер әлемнің үздік 200 оқу орнында оқыды ",
  Fact7H: "37%",
  Fact7P: "түлектер бірден жұмысқа орналасады",
  Fact8H: "63%",
  Fact8P: "түлектер бірден жоғары оқу орындарына түседі ",
  Fact9H: "394",
  Fact9P: "студенттер колледжде оқиды",
  Fact10H: "270",
  Fact10P: "студенттер үйіндегі орындар ",
  Fact11H: "3",
  Fact11P: "оқытудың негізгі бағыттары: Pedagogy, IT, Accounting",

  // HistoryPage Texts
  HistoryPage: {
    title: "Колледж тарихы",
    podtitle: "Басты бет / Колледж тарихы",
  },

  HistoryP1:
    "Жамбыл облысы әкімдігі білім басқармасының 'Жамбыл Инновациялық Жоғары колледжі' коммуналдық мемлекеттік қазыналық кәсіпорны 1993 жылы құрылды. Колледж бұрынғы қазақ-түрік колледжі ретінде көпшілікке танымал.",
  HistoryP2:
    "Колледжде Қазақстан Республикасы Білім және ғылым министрлігі бекіткен бағдарлама бойынша заманауи кәсіптік-техникалық білім беріледі.",
  HistoryP3:
    "Колледж 'Білім Инновация' МОФ білім беру және тәрбие процестерінің сапа стандарттарына сәйкес жұмыс істейді (Білім Инновация лицейлер желісінің операторы) және' SDU University 'қамқорлығымен жұмыс істейді.",
  HistoryP4:
    "Жаратылыстану-математикалық және кәсіби бағыттағы барлық пәндер ағылшын тілінде оқытылады. Құндылыққа бағытталған білімге ерекше назар аударылады.",
  HistoryP5:
    "Колледж экономикалық және педагогикалық бейіндегі мамандарды, сондай-ақ ақпараттық технологиялар саласындағы мамандарды дайындайды.",

  // ProfessionPage Texts
  ProfessionPage: {
    title: "Мамандықтар",
    podtitle: "Басты бет / Мамандықтар",
  },
  ProfessionH1: "Бағдарламалық қамтамасыз ету",
  ProfessionH2: "Оқыту тілдері:",
  ProfessionH3: "Есеп және аудит",
  ProfessionH4: "Оқыту тілдері:",
  ProfessionP1:
    "Оқу орнында сіз ақпараттық технологиялардың әртүрлі аспектілерін, соның ішінде бағдарламалауды, желілік технологияларды және дерекқорларды зерттеу арқылы ат дәрежесін ала аласыз. Оқыту бағдарламалық жасақтаманы әзірлеу, жүйелерді басқару және ақпараттық технологиялар жобаларын басқару сияқты көптеген тақырыптарды қамтиды.",
  ProfessionP2: "Бұл мамандыққа талапкерлер тек грантқа түседі.",
  ProfessionP3: "Оқу мерзімі 2 жыл 10 ай",
  ProfessionP4:
    "Колледж бухгалтерлік есеп пен аудитті үйретеді. Мамандық ACCA халықаралық бағдарламасы бойынша оқытылады, бұл ACCA сертификатын алудың бірегей мүмкіндігін ашады. Бұл бағдарлама студенттерге бухгалтерлік есеп бойынша теориялық білім беріп қана қоймайды, сонымен қатар оларды қаржы және бизнес саласындағы табысты мансап үшін маңызды актив болып табылатын ACCA халықаралық стандарттарына сәйкестендіруге дайындайды.",
  ProfessionP5:
    "Талапкерлер осы мамандық бойынша тек ақылы негізде оқи алады. Оқу құны ... тг",
  ProfessionP6: "Оқу мерзімі 2 жыл 10 ай",
  ProfessionLi1: "қазақша",
  ProfessionLi2: "ағылшын",
  ProfessionLi3: "орыс",
  ProfessionLi4: "түрік",
  ProfessionBtn1: "Толығырақ",
  // Translate Texts
  TranslatePage: {
    title: "Ауысу",
    podtitle: "Басты бет / Колледжден ауысу",
  },
  TranslateH1: "Колледжден ауысу ережелері",
  TranslateP1:
    "Қазақстан Республикасы Білім және ғылым министрінің 2015 жылғы 20 қаңтардағы № 19 бұйрығы. Техникалық және кәсіптік, орта білімнен кейінгі білім беру саласындағы мемлекеттік қызмет көрсету қағидаларын бекіту туралы",
  TranslateP2:
    "Білім алушыларды бір оқу орнынан басқасына, оның ішінде мемлекеттік білім беру тапсырысынан мемлекеттік білім беру тапсырысына, бір мамандықтан басқасына, ақылы негізден мемлекеттік білім беру тапсырысы бойынша оқуға немесе оқытудың бір нысанынан басқасына ауыстыру оқу жұмыс жоспарларының пәндері/модульдері бойынша оқыту нәтижелерінің академиялық айырмашылықтарын тапсырған кезде жүзеге асырылады.",
  TranslateP3:
    "Ақылы негізден мемлекеттік білім беру тапсырысы бойынша ауыстыру осы оқу орнында орындардың босауына қарай оқу жылы ішінде жүзеге асырылады.",
  TranslateP4:
    "Кәмелетке толмаған білім алушының ата-анасы немесе заңды өкілдері басқа жерге көшкен жағдайда, растайтын құжаттарды ұсынған кезде оны каникул кезеңінен тыс ауыстыруға жол беріледі.",

  TranslateP5:
    "Қалған жағдайларда білім алушыларды ауыстыру жазғы және қысқы демалыс кезеңінде жүзеге асырылады.",

  TranslateP6:
    "Ақылы негізден мемлекеттік білім беру тапсырысы бойынша оқуға ауыстыру үшін техникалық және кәсіптік, орта білімнен кейінгі білімнің білім беру бағдарламаларын іске асыратын білім беру ұйымы мемлекеттік білім беру тапсырысы бойынша бос орындар туралы ақпаратты білім беру ұйымының ақпараттық стендтерінде, ресми интернет-сайттарында орналастырады.",

  TranslateP7:
    "      Білім алушыларды ақылы оқудан мемлекеттік білім беру тапсырысы бойынша оқуға ауыстыру үшін білім беру ұйымында педагогтердің және студенттік өзін-өзі басқару органдары өкілдерінің қатысуымен алқалы орган құрылады. Білім алушыны ауыстыру туралы шешімді оның үлгерімін ескере отырып алқалы орган қабылдайды.",

  // JumbotronElem Texts
  JumbotronElemPage: {
    title: "Құжаттарды тапсыру",
    podtitle: "Басты бет / Құжаттарды тапсыру",
  },
  JumbotronElemH1: "Басты бет / Құжаттарды тапсыру",
  JumbotronElemH2: "Оқуға түсу үшін қажетті құжаттар:",
  JumbotronElemP1:
    "Техникалық және кәсіптік, орта білімнен кейінгі білімнің білім беру бағдарламаларын іске асыратын білім беру ұйымдарына оқуға қабылдаудың үлгілік қағидаларын бекіту туралы Қазақстан Республикасы Білім және ғылым министрінің 2018 жылғы 18 қазандағы № 578 бұйрығы.",
  JumbotronElemP2:
    "Тұлғалардың колледге оқуға өтініштерін қабылдау:  оқудың күндізгі нысанына: мемлекеттік тапсырыс бойынша 9 сынып бойынша күнтізбелік жылдың 25 маусымы мен 18 тамызы аралығында,  ақылы негізде 25 маусымы мен 25 тамыз аралығында жүргізіледі.",
  JumbotronElemP3:
    "Мемлекеттік көрсетілетін қызметті алу үшін көрсетілетін қызметті алушы ТжКОББ-ның білім беру ұйымына (бұдан әрі - көрсетілетін қызметті беруші) не 'электрондық үкімет' www.egov.kz  веб-порталына (бұдан әрі - портал) жүгіне алады.",
  JumbotronElemP4:
    "Оқуға түсу үшін құжаттарды кәмелетке толған адамдар жеке өзі, кәмелетке толмағандар – заңды өкілінің қатысуымен ұсынады.",
  JumbotronElemLI1: "құжаттарды қабылдау туралы өтініш;",
  JumbotronElemLI2: "білімі туралы құжаттың түпнұсқасы;",
  JumbotronElemLI3: "3x4 см көлеміндегі 4 дана фотосурет;",
  JumbotronElemLI4:
    "'Денсаулық сақтау саласындағы есепке алу құжаттамасының нысандарын бекіту туралы' Қазақстан Республикасы Денсаулық сақтау министрінің міндетін атқарушының 2020 жылғы 30 қазандағы № ҚР ДСМ-175/2020 бұйрығымен (Нормативтік құқықтық актілерді мемлекеттік тіркеу тізілімінде № 21759 болып тіркелген) бекітілген № 075-У нысаны",

  // Hero Texts
  HeroH1P1: "Сапалы білім",
  HeroH1P2: "Саналы тәрбие",
  // Column,
  ColumnD1P1: "Құндылыққа бағытталған білім беру",
  ColumnD1P2: "білім",
  ColumnD2P1: "4 тілде оқыту",
  ColumnD2P2: "тәрбие",
  ColumnD3P1: "4 тілде",
  ColumnD3P2: "оқыту",
  ColumnD4P1: "Тәжірибелі",
  ColumnD4P2: "мұғалімдер",


  // Jumbotron,
  JumbotronH1P1: "Жамбыл Инновациялық",
  JumbotronH1P2: "жоғары колледжіне қош",
  JumbotronH1P3: "келдіңіздер!",
  JumbotronP1P1: "Білім құндылықтарға, мүмкіндіктерге және шабытқа толы біздің оқу орнына қош келдіңіз. Осы колледждің директоры ретінде Мен сізге әр адамның өз әлеуетін ашуға мүмкіндігі бар бірегей білім беру ортасын ұсынғанымды мақтан тұтамын.",
  JumbotronP1P2: "Біздің құндылықтарымыздың негізінде сапалы білім алуға деген ұмтылыс жатыр. Біздің колледж әртүрлі білім салаларындағы студенттерге бірегей мүмкіндіктер ұсынады. Біз студенттер идеялармен алмасатын, тәжірибелі оқытушылармен ынтымақтаса алатын және ғылыми жобаларға қатыса алатын инновациялық ортаны құруға тырысамыз.",
  JumbotronP1P3: "Біздің колледжге деген қызығушылығыңыз үшін рахмет. Мұнда сіз тек білім ғана емес, сонымен қатар ең өршіл мақсаттарыңызға жету үшін шабыт таба алатындығыңызға сенімдіміз.",
  JumbotronP1P4: "Колледж директоры",
  JumbotronP1P5: "Айбеков Айдын Абрашұлы",
  // Column2,
  Column2H1: "Біздің артықшылықтарымыз",
  Column2P1: "Құндылыққа бағытталған білім беру",
  Column2P2: "4 тілде оқыту",
  Column2P3: "Модульдік – құзыреттілікке негізделген тәсіл",
  Column2P4: "Сапалы тәжірибелі мұғалімдер",
  Column2P5: "Сабақтар WorldSkills Competences, ACCA және 1C стандарттары негізінде жүргізіледі",
  Column2P6: "Шетелдік және отандық үздік білімі бар мұғалімдер",
  

  // Column3,
  Column3H1: "Маңызды сандар",
  Column3P1: "Мұғалімдердің IELTS орташа баллы",
  Column3P2: "Мұғалім",
  Column3P3: "Студент",
  Column3P4: "Негізгі оқу бағыттары",
  //   Footer Texts
  FooterH1: "Байланыс үшін",
  FooterH2: "Әлеуметтік желілер",
  FooterL1: "Тараз қ. Пушкина 154",
  //   SecondNav Texts
  SecondNavP1: "Байланыс үшін",
  SecondNavP2: "Бізге хабарласыңыз",
  //   Navbar Texts
  NavbarP1: "080003 Тараз қаласы, Пушкин көшесі, 154",
  //   ThirdNav Texts
  ThirdNavNavItem0: "Басты бет",
  ThirdNavNavItem1: "Колледж",
  ThirdNavNavItem2: "Талапкерлерге",
  ThirdNavNavItem3: "Мамандықтар",
  ThirdNavNavItem4: "Инфраструктура",
  ThirdNavNavItem5: "Студенттерге",
  ThirdNavNavItem6: "Ата-аналарға",
  ThirdNavNavItem7: "Жұмыс берушілерге",
  ThirdNavNavItem8: "Түлектерге",
  ThirdNavNavItem9: "Календарь",
  //   ThirdNav drop-down item text
  ThirdNavNavItem1Li1: "Колледж тарихы",
  ThirdNavNavItem1Li2: "Маңызды фактілер",
  ThirdNavNavItem1Li3: "Құжаттар",
  ThirdNavNavItem1Li4: "Команда",
  ThirdNavNavItem1Li5: "Колледж структурасы",
  ThirdNavNavItem1Li6: "Қамқоршылар кеңесі",
  ThirdNavNavItem1Li7: "Хабарландыру",
  
  ThirdNavNavItem2Li1: "Мамандықтар",
  ThirdNavNavItem2Li2: "Құжаттарды тапсыру",
  ThirdNavNavItem2Li3: "Ауысу",
  
  ThirdNavNavItem3Li1: "Бағдарламалық қамтамасыз ету",
  ThirdNavNavItem3Li2: "Есеп және аудит",
  ThirdNavNavItem3Li3: "Педагогика-математика",
  
  ThirdNavNavItem4Li1: "Корпус",
  ThirdNavNavItem4Li2: "А корпусы (оқу корпусы + акт залы)",
  ThirdNavNavItem4Li3: "Б корпусы + Жатақхана",
  ThirdNavNavItem4Li4: "Спортзал",
  ThirdNavNavItem4Li5: "Футбол алаңы",
  ThirdNavNavItem4Li6: "Барбекюге арналған беседка",
  ThirdNavNavItem4Li7: "Асхана",
  ThirdNavNavItem4Li8: "Кір жуатын орын",
  
  ThirdNavNavItem5Li1: "Академиялық календарь",
  ThirdNavNavItem5Li2: "Электронды журнал",
  ThirdNavNavItem5Li3: "Практика , Карьера , Проф. ориентация",
  ThirdNavNavItem5Li6: "Комитеттер",
  ThirdNavNavItem5Li7: "Студенттік өмір",
  ThirdNavNavItem5Li8: "Студент үйі",

  ThirdNavNavItem6Li1: "Ата-ана комитеті",
  ThirdNavNavItem6Li2: "Колледжбен байланысу",
  ThirdNavNavItem6Li3: "Мұғаліммен байланысу",

  ThirdNavNavItem7Li1: "Серіктестер",
  ThirdNavNavItem7Li2: "Мамандарды даярлау",

  ThirdNavNavItem8Li1: "Ассоциация",
  ThirdNavNavItem8Li2: "Спонсорлық",
  
  ThirdNavNavItem9Li1: "Google Calendar",
  // AcademicCalendar Texts
  AcademicCalendarInfoText: "Білім беру үдерісіне және мемлекеттік білім беру бағдарламасына қатысушылардың қажеттіліктерін ескере отырып, жылдық күнтізбелік оқу кестесі оқу жылы 1 қыркүйекте басталып, 28 маусымда аяқталатындай етіп құрастырылған.",
  AcdemicCalendarP1: "kzГрафик 2022-2023 учебного года будет следующим:",
  AcdemicCalendarP2:
    "Просмотреть наш годовой календарь на 2023-2024 учебный год можно здесь",
  AcdemicCalendarLi1:
    "1-ая четверть: с 21 августа 2023 г. по 13 октября 2023 г. (8 недель)",
  AcdemicCalendarLi2:
    "2-ая четверть: с 23 октября 2023 г. по 22 декабря 2023 г. (9 недель)",
  AcdemicCalendarLi3:
    "3-яя четверть: с 8 января 2024 г. по 15 марта 2024 г. (10 недель)",
  AcdemicCalendarLi4:
    "4-ая четверть: с 25 марта 2024 г. по 31 мая 2024 г. (10 недель)",

  // Accounting Texts
  accountingPage: {
    title: "Есеп және аудит",
    podtitle: "Басты бет / Есеп және аудит",
  },

  AccountingInfoH1: "Есеп және аудит",
  AccountingInfoH2: "Оқытылатын пәндер",
  
  AccountingInfoP1: "Колледжде сіз ACCA бағдарламасы бойынша бухгалтерлік есепті оқи аласыз, бұл ACCA сертификатын алуға бірегей мүмкіндік береді. Бұл бағдарлама студенттерді бухгалтерлік есеп бойынша теориялық біліммен қамтамасыз етіп қана қоймайды, сонымен қатар оларды қаржы және бизнес саласындағы мансаптағы құнды актив болып табылатын ACCA халықаралық стандартына дайындайды.",
  
  AccountingInfoLi1: "Ағылшын және математиканы тереңдетіп оқыту",
  AccountingInfoLi2: "AССA кәсіби сертификаттау талаптары мен стандарттарына сәйкес оқыту (Қаржы, Есеп және аудит саласындағы мамандарды біріктіретін Британдық сертификатталған алқабилер қауымдастығы)",
  AccountingInfoLi3: "1С үйрену",
  AccountingInfoLi4: "Қазақстандық салық салуды игеру",
  AccountingInfoLi5: "Тәжірибелі және озық оқытушылар",
  AccountingInfoLi6: "Аудиторлық фирмада тағылымдамадан өту мүмкіндігі",

  AccountingInfoCard1: "Менеджмент",
  AccountingInfoCard2: "Аудит",
  AccountingInfoCard3: "Маркетинг",
  AccountingInfoCard4: "Финанс",
  // IT Texts
  ItPage: {
    title: "Бағдарламалық қамтамасыз ету",
    podtitle: "Басты бет / Бағдарламалық қамтамасыз ету",
  },

  ItH1: "Бағдарламалық қамтамасыз ету",
  ItH2: "Оқытылатын бағыттар",
  ItP1: "Колледжде сіз ақпараттық технологиялардың әртүрлі аспектілерін, соның ішінде бағдарламалауды, желілік технологияларды және мәліметтер базасын зерттеу арқылы ат дәрежесін ала аласыз. Оқыту бағдарламалық жасақтаманы әзірлеу, жүйелерді басқару және ақпараттық технологиялар жобаларын басқару сияқты көптеген тақырыптарды қамтиды.",
  ItLi1: "Грантта оқыту",
  ItLi2: "WorldSkills база компетенциясында оқыту",
  ItLi3: "IT хакатондар мен StartUp-тарға қатысу",
  ItLi4: "Модульдерге бөліп оқыту",
  ItLi5: "Тәжірибелі және озық оқытушылар",
  ItLi6: "ІТ компанияларда практикадан өту",

  ItCard1: "UI/UX дизайн",
  ItCard2: "Графикалық дизайн",
  ItCard3: "Веб бағдарламалау",
  ItCard4: "Мобильді бағдарламалау",

  //Pedagokika-matematika
  PedogogikaMathH1: "Педагогика математика",
  PedogogikaMathH2: "Оқытылатын пәндер",

  PedogogikaMathP1: "Колледжде сіз әртүрлі математикалық пәндерді, соның ішінде алгебра, геометрия, Математикалық талдау және ықтималдық теориясын оқып, математика пәнінің мұғалімі дәрежесін ала аласыз. Оқуды аяқтағаннан кейін және дәреже алғаннан кейін сіз математиканы орта деңгейде оқытуға кірісе аласыз.",
  PedogogikaMathP2: "Ағылшын тілін және математиканы тереңдетіп оқыту",
  PedogogikaMathP3: "Жоғары математиканы меңгеру",
  PedogogikaMathP4: "Тәжірибелі және озық оқытушылар",
  PedogogikaMathP5: "Мектептерде тағылымдамадан өту мүмкіндігі",
  PedogogikaMathP6: "Жоғары математика",
  PedogogikaMathP7: "Алгебра",
  PedogogikaMathP8: "Геометрия",
  PedogogikaMathP9: "Математикалық анализ",

    //PreperationProf
  PrepProfH1: "Электронды журнал",
  PrepProfP1: "Edupage-мұғалімдер, студенттер және ата-аналар арасындағы тиімді өзара әрекеттесуді қамтамасыз ететін заманауи колледждер мен мектептер үшін ажырамас құрал. Бұл нақты уақыт режимінде сабақ кестесі, үй тапсырмасы және мектеп өмірінің жаңалықтары туралы жаңартылған ақпаратқа қол жеткізуді жеңілдететін қосымша болып табылады.",

  //Profession description
  ProfessionDesH1: "КОЛЛЕДЖДЕГІ БІЛІМ БЕРУДІҢ ЖАЛПЫ ҚҰРЫЛЫМЫ",
  ProfessionDesH2: "КОЛЛЕДЖДЕ ОҚУ ПРОЦЕСІНІҢ ТІЛДІК КАРТАСЫ",
  ProfessionDesH3: "INFORMATION TECHNOLOGY",
  ProfessionDesH4: "БІЗ ОНЫҢ КӨПТЕГЕН САЛАЛАРЫН ҚАМТИМЫЗ:",
  ProfessionDesH5: "СІЗ БОЛА АЛАСЫЗ:",
  ProfessionDesH6: "ACCOUNTING",
  ProfessionDesH7: "TEACHER TRAINING (MATHEMATICS)",
  ProfessionDesH8: "Толығырақ",

  ProfessionDesP1: "Нақты жаратылыстану бағытындағы пәндер ағылшын тілінде оқытылатын мектеп бағдарламасын аяқтау, ағылшын тілін қарқынды меңгеру, математиканы тереңдету және түрік тілінің негіздерін алу.",
  ProfessionDesP2: "Кәсіби және арнайы пәндерді ағылшын тілінде оқу. ",
  ProfessionDesP3: "Кәсіби және арнайы пәндерді ағылшын тілінде оқуды жалғастыру және игерілген пәндерді тәжірибеде қолдану үшін өндірісте тәжірибелік сабақтардан өту.",

  ProfessionDesP4: "20% ҚАЗАҚ ТІЛІНДЕ",
  ProfessionDesP5: "5% ОРЫС ТІЛІНДЕ",
  ProfessionDesP6: "5% ТҮРІК ТІЛІНДЕ",
  ProfessionDesP7: "70% АҒЫЛШЫН ТІЛІНДЕ",
  
  ProfessionDesP8: "Қазіргі заманғы технологиялар қазір барлық жерде және олар әлемді өзгертеді және күн сайын біздің өмірімізге әсер етеді. АТ-ға жан-жақты және мамандандырылған тәсіл сізде таңдаудың кең ауқымы және үлкен перспективалар бар екенін білдіреді. Біздің серіктестер – қазақстандық ақпараттық технологиялар нарығында мықты позицияға ие компаниялар.",
  ProfessionDesP9: "Бағдарламалау (Java, Python)",
  ProfessionDesP10: "Қолданбаларды әзірлеу  (Application development)",
  ProfessionDesP11: "Графикалық дизайн (Graphic Design)",
  ProfessionDesP12: "IT менеджмент (IT Management)",

  ProfessionDesP13: "Бағдарламашы (Java, Python)",
  ProfessionDesP14: "Веб-сайт әзірлеушісі (Full Stack)",
  ProfessionDesP15: "Дизайнер (Graphic, UX/UI, 3D)",
  ProfessionDesP16: "Жоба менеджері (Project Manager)",

  ProfessionDesP17: "ОҚУ МЕРЗІМІ 2 жыл 10 ай",
  ProfessionDesP18: "ОҚУ АҚЫСЫ  Грант (таңдау қатаң түрде 9-сыныптың аттестатына  негізделеді)",
  ProfessionDesP19: "Грант бөліміне шәкіртақы мен жеңілдіктер әрбір студентке В-дан төмен баға болмаған жағдайда ай сайын  32,000 теңге төленеді.",
  
  ProfessionDesP20: "Бухгалтерлік есеп бағдарламасы бухгалтерлік есеп және қаржы саласында теориялық білімі бар, халықаралық қаржылық есептілік стандарттарын білетін, сондай-ақ халықаралық және жергілікті еңбек нарығында жұмыс істеу үшін қажетті кәсіби құзыреттілік пен техникалық дағдыларды меңгерген мамандарды даярлауға бағытталған.",
  ProfessionDesP21: "Кәсіби оқытушылар мен практиктер біздің бағдарламамызды қолданбалы етіп, түлектердің жұмысқа орналасуының жоғары деңгейін қамтамасыз етеді. ACCA (Association of Chartered Certified Accountants) халықаралық сертификаттау стандарттары біздің бағдарламамызды еңбек нарығының заманауи шешімдеріне толық сәйкес келетін өзекті және жан-жақты болуға бағыттайды.",
  
  ProfessionDesP22: "Бухгалтер",
  ProfessionDesP23: "Менеджер",
  ProfessionDesP24: "Аналитик",
  ProfessionDesP25: "Қаржыгер",
  
  ProfessionDesP26: "ОҚУ АҚЫСЫ  1 оқу жылына 645 000 теңге (ақылы бөлім, грант қарастырылмаған)",
  ProfessionDesP27: "ЖЕҢІЛДІКТЕР 1 семестрден кейін тобындағы ең жақсы 5 студентке оқу ақысының 10-нан 20% дейін жеңілдіктер беріледі.",

  ProfessionDesP28: "Бағдарлама педагогика және әдістемелік заманауи оқыту аппаратын жан-жақты іргелі теориялық дайындығын қалыптастыруға, студенттердің педагогика және психология, әдістеме бойынша теориялық және практикалық білімдерін тереңдетуге, ғылымның басқа салаларымен интеграциялау, математиканы оқытудың заманауи тұжырымдамалары мен әдістерін меңгеруге бағытталған. Математика пәнін терең түсіну және білу үшін математика ғылымын тиянақты оқу міндетті.",
  ProfessionDesP29: "Интеграцияланған пәндік-тілдік оқыту технологиясына, құндылыққа бағдарланған тәрбиеге және математиканы оқытудың қазіргі заманғы әдістеріне сәйкес Үштілділік жағдайында кәсіби қызметті жүзеге асыруға және оңтайландыруға қабілетті ағылшын тілінде оқытатын педагог кадрларды даярлау.",

  ProfessionDesP30: "ОҚУ МЕРЗІМІ  3 жыл 10 ай",
  ProfessionDesP31: "ОҚУ АҚЫСЫ  Грант (таңдау қатаң түрде 9-сыныптың аттестатына  негізделеді)",
  ProfessionDesP32: "Грант бөліміне шәкіртақы мен жеңілдіктер әрбір студентке В-дан төмен баға болмаған жағдайда ай сайын 32000 теңге төленеді.",

 StuLifeP1: "Біздің колледждегі студенттік өмір мәдени және спорттық іс - шаралардың алуан түрлілігімен айқын көрінеді және құндылыққа бағытталған білім беру жүйесіне сәйкес келеді - QUNDYLYK.",
 StuLifeP2: "Жыл сайынғы іс-шаралар мен концерттер студенттерге мәдени әртүрлілікке толығымен енуге мүмкіндік беретін жағымды және шабыттандыратын атмосфера жасайды және біздің оқу орнында достық және жігерлі орта құрайды.",
 StuLifeP3: "Сонымен қатар, студенттік клубтар мен ұйымдар көшбасшылық пен әлеуметтік жауапкершілік дағдыларын дамытуға ықпал ететін жарыстарды белсенді түрде өткізеді.",
 StuLifeP4: "Жастар комитеті жөніндегі комитеттердің басшылары студенттік өмірдің жетекшілері болып табылады",
 StuLifeP5: "ұлдардың студенттік өмірінің жетекшісі. Email:",
 StuLifeP6: "қыздардың студенттік өмірінің жетекшісі. Email:",

 Stuhouse1: "Колледж әр студентке кампустағы Студенттік үйге (жатақханаға) орналасуға өтініш беру мүмкіндігін ұсынады.",
 Stuhouse2: "Жатақханада тұру барлық студенттерге өте жоғары білім жүктемесі мен оқудың күрделілігіне байланысты қатаң ұсынылады, 1 курс студенттері үшін студенттің үйінде тұру міндетті екенін білу маңызды",
 Stuhouse3: "Студенттер студенттің үйіндегі ішкі тәртіп ережелерін сақтауға міндетті.",
 Stuhouse4: "ЕРЕЖЕ",

 Sponship1: "Түлектер қауымдастығының қауымдастық қызметін дамыту және колледжде әлеуметтік жобаларды іске асыру мақсатында қаржы қаражатын тұрақты жинақтауға арналған ресми платформасы бар.",
 Sponship2: "Әр түлек өзінің сүйікті колледжіне өз үлесін қоса алады",
 Sponship3: "Патреонға тіркелу бойынша нұсқаулық",
 Sponship4: "Колледжге үлес қосу үшін QR кодты сканерлеу арқылы  Patrеon-ға тіркеліңіз",

 CareerH1: "Колледжде кәсіби бағдарлау және мансапты жоспарлау орталығы жұмыс істейді",
 CareerH2: "1 Кезең - Өзін-Өзі Талдау",
 CareerH3: "2 Кезең - Әрекет",
 CareerH4: "3 кезең - Кері байланыс",

 CareerP1: "Біз студенттерге дамудың әрі қарайғы траекториясын дұрыс таңдауға, ЖОО-ға немесе мансапқа түсуді жоспарлауға және даярлықтың жеткілікті жоғары деңгейімен оқу кезеңінде жұмыс іздеуге көмектесуді бастаймыз. Алдымен дайындық кезеңі басталады, содан кейін біз бірге нақты әлемге шығып, дұрыс траекторияны іздейміз.",
 CareerP2: "Біздің бағдарламамыздың арқасында студенттер мен түлектер еңбек нарығына сенімді шығуға немесе өзі армандаған ЖОО-ға түсуге дайындалуда. Ол үшін біз алдымен мақсат қойып, көп жұмыс жасаймыз, әрбір студенттің жеке тұлғалық қабілетін бірге талдаймыз, өз өсу нүктелерін түсінуге көмектесеміз және ЖОО-ға түсуге дайындықтың дұрыс жолын және де еңбек нарығына шығу жолын таңдаймыз",
 CareerP3: "Бұл кезеңде студенттер мен түлектер бағдарламаның бірінші кезеңінде алған білімдерін өз бетінше қолдана бастайды. Студенттің өзі университетке түсуге дайындық бағдарламасын жасайды, қажетті емтихандарға дайындала бастайды және университетке құжаттарды тапсыру тәсілін үйренеді немесе өзінің портфолиосын құра бастайды және қажетті жобаларға қатысу, түйіндеме мен ілеспе хаттар жасау, сұхбаттасуға өзін таныстыруға үйрету, талдау арқылы тапсырмаларды орындайды. еңбек нарығы және ең бастысы тәжірибе орнын дұрыс таңдау одан әрі жұмысқа орналастыру мақсатында жұмыс жасайды.",
 CareerP4: "Сұхбаттан кейін біз атқарылған жұмыстың нәтижесін талқылаймыз. Қателерді талдап, жақсы жақтарын нығайтамыз.",
 CareerP5: "Орталық басшысы - Жанаев Ғазизбек",

 ComitH1: "Академиялық сапа комитеті",
 ComitH2: "Тәртіптік комитет",

 ComitP1: "Академиялық сапа комитеті колледждің академиялық қызметі жөніндегі алқалы орган болып табылады. Академиялық сапа жөніндегі комитет жұмысының негізгі бағыттары колледждің ішкі ережелері шеңберінде оқу процесінің тиімділігін арттыру болып табылады. Төмен академиялық жетістіктерді көрсететін студенттер (үш пән бойынша 60 балдан төмен немесе аралық аттестаттау қорытындысы бойынша 1 пән бойынша 50 балдан төмен бағалардың болуы) ата-аналарымен бірге Комитет отырысына шақырылады. Прогресс болмаған жағдайда немесе 1 пән бойынша қорытынды баға 50-ден төмен балл алған жағдайда, комитет студентті басқа колледжге ауыстыруға ұсынады немесе оқудан шығаруға жібереді.",
 ComitP2: "Тәртіптік комитет колледж студенттерінің колледждің ішкі тәртіпті сақтауын қамтамасыз ету және бақылау жөніндегі алқалы орган болып табылады. Комитет жұмысының негізгі бағыттары колледждің ішкі тәртібін бұзушылықтарды талдау болып табылады. Студенттер колледж қызметкерлерінің іскерлік этиканы бұзу жағдайларын қарау үшін комитетке жүгінуге құқылы. Ішкі тәртіп ережелерін бұзатын студенттер бұзушылықтардың себептерін анықтау үшін комитет отырыстарына (қажет болған жағдайда ата-аналарымен бірге) шақырылады, егер студенттің мінез-құлқында өзгеріс болмаса, комитет студентті басқа колледжге ауыстыруға ұсынады немесе оқудан шығаруға жібереді.",

 ParComit1: "Ата — аналар комитеті-колледж өмірінде маңызды рөл атқаратын маңызды орган. Ол студенттердің ата-аналарының мүдделерін білдіреді және оқу орнының әкімшілігімен тығыз байланыста жұмыс істейді.",  
 ParComit2: "Ата-аналар комитетінің міндеті-колледждің дамуына ықпал ету және студенттердің білім алуына жақсы жағдай жасау. Ата-аналар комитетінің мүшелері колледж өміріне белсенді қатысады, студенттердің ата-аналарына жиналыстар мен кеңестер өткізеді, педагогикалық құраммен және әкімшілікпен байланыста болады.",  
 ParComit3: "Ата-аналар комитеті әлеуметтік қайырымдылық жұмыстарымен де белсенді айналысады. Ол мұқтаж студенттерге қаржылық және ұйымдастырушылық қолдау көрсетеді, мәдени-демалыс іс-шараларын ұйымдастырады, басқа білім беру ұйымдарымен ынтымақтастықты дамытуға ықпал етеді. Ата-аналар комитеті студенттерге өздерінің таланттары мен әлеуеттерін дамытуға мүмкіндік беретін қолайлы және әртүрлі білім беру ортасын қамтамасыз етуге арналған.",  
 ParComit4: "Әр топ әр оқу жылының қыркүйек айында комитетке ата-аналарының арасынан 1 мүшені таңдайды. Ата-аналар комитетінің мүшелері ата-аналар комитетінің төрағасын 1 оқу жылына сайлайды.",  
 ParComit5: "Ата-аналар комитеті директордың ата-аналармен жұмыс жөніндегі орынбасарларымен тығыз жұмыс жасайды",  

 ConTeacherP1: "Оқытушылармен өзара іс - қимыл негізінен ата-аналармен және студенттермен ресми байланыс арналары-Edupage (колледждегі электрондық портал) арқылы жүзеге асырылады. Әрбір ата-ана Edupage-ге тіркелуге және қосымшаны смартфонға орнатуға міндетті. Оқытушыларға барлық сұрақтар мен өтініштер Edupage арқылы жіберілуі керек. Оқытушылар да Edupage арқылы ата-аналармен қарым-қатынаста болады.",
 ConTeacherP2: "Ата-аналардың оқытушылармен (бетпе-бет) тиімді офлайн кездесулерін қамтамасыз ету үшін әр семестрде 'Ата-аналардың оқытушылармен кездесулері' ұйымдастырылады. Осы кездесулерде әрбір ата-ана мен оқытушының білім беру аспектілерін жеке талқылауға мүмкіндігі бар.",
 ConTeacherP3: "Мұғаліммен жоспардан тыс кездесу қажет болған жағдайда Edupage арқылы алдын-ала байланысу керек, мұғалім міндетті түрде жауап береді және кездесу уақыты мен күні бойынша бірнеше нұсқаны ұсынады, ата-ана дұрыс уақытты таңдап, колледжге келгенде мұғаліммен кездесуге келеді.",
 ConTeacherP4: "Ата-аналарға сабаққа қатысуға рұқсат етілмейді.",

 WorkP1: "JIHC 'БІЛІМ-ИННОВАЦИЯ' халықаралық қоғамдық қорының басшылығымен және 'SDU University' қамқорлығымен білім беру мекемелерінің желісіне кіретін мемлекеттік колледж болып табылады",
 WorkP2: "Инновациялық тәсілдермен, ағылшын тіліндегі білім беру саясатымен және құндылыққа бағытталған тәрбиемен JIHC 1993 жылдан бастап елдегі кәсіптік - техникалық білім берудің флагманы болып табылады.",
 WorkP3: "Колледж серіктестердің қажеттіліктеріне сұранысқа ие мамандарды даярлау мақсатында өзекті оқу бағдарламаларын құру арқылы жұмыс берушілермен тығыз өзара іс-қимылға ашық.",
 WorkP4: "Қазіргі уақытта колледж келесі серіктестермен тығыз жұмыс істейді",
 WorkP5: "Біз өзіміздің кадрлық әлеуетімізді арттыруға мүдделі ұйымдармен ынтымақтастыққа ашықпыз",
 WorkP6: "Орталық басшысы-Жанаев Ғазизбек",

 Calendar: "Бұл бөлімде сіз Google Calendar арқылы көрсетілетін колледждегі барлық маңызды оқиғаларды көресіз.",

 Edupage: "Оқытушылармен өзара іс - қимыл негізінен ата-аналармен және студенттермен ресми байланыс арналары-Edupage (колледждегі электрондық портал) арқылы жүзеге асырылады. Әрбір ата-ана Edupage-ге тіркелуге және қосымшаны смартфонға орнатуға міндетті. Оқытушыларға барлық сұрақтар мен өтініштер Edupage арқылы жіберілуі керек. Оқытушылар да Edupage арқылы ата-аналармен қарым-қатынаста болады.",

  ContactP1: "Құндылыққа бағытталған білім беру тұжырымдамасы - колледж мен студенттердің ата-аналары арасындағы тығыз қарым-қатынастың маңызды шарты болып табылады. Тәрбие және білім беру процестері мұғалімдер мен ата-аналар арасында тығыз үйлестірілуі керек. Колледжде жүзеге асырылып жатқан процестерді толық түсіну үшін ата аналар жүйелі түрде келесі іс шараларды өткізеді:",
  ContactP2: "Топ кураторлары мен тәрбиешілерінің басшылығымен семестрде 1 рет ата аналар жиналысын өткізу",
  ContactP3: "Оқу жылы ішінде әр ата-ананы тәрбие сағатына шақыру",
  ContactP4: "Ата-аналардың белсенді қатысуымен спорттық және мәдени іс-шараларды ұйымдастыру (Наурыз, Этикалық кештер және т. б.)",
  ContactP5: "Студенттің отбасына 1 курс ішінде 1 рет бару",
  ContactP6: "Колледжге түсу жоспарын бекіту және/немесе мансапты жоспарлау үшін ата-ананы колледжде оқудың соңғы жылында колледжге шақыру",
  ContactP7: "Семестрде 1 рет студенттің үлгерімін талдау үшін 'Ата-аналар мен оқытушылар кездесулерін' ұйымдастыру",
  ContactP8: "Қажет болған жағдайда ата-ананы академиялық сапа комитетінің және/немесе тәртіптік кеңестің отырыстарына шақыру",
  ContactP9: "Ата-аналарға арналған үйірмелер ұйымдастыру",
  ContactP10: "'Аналар академиясы' және 'Әкелер кеңесі' жұмыс жоспарын іске асыру",
  ContactP11: "Ата-аналармен жұмысты директордың ата-аналармен жұмыс жөніндегі орынбасарлары ұйымдастырады.",
};
