<template>
  <div class="container mb-4 mt-4">
    <div class="link-container buttom-br mb-3">
      <a
        class="p-0 m-0 link-item"
        href="https://drive.google.com/file/d/1O1eSO0YejLQcWk36qJ7gNIpB2TXJo8Hk/view?usp=sharing"
      >
        {{ $t("DocumentP1") }}
      </a>
    </div>

    <div class="link-container mb-3">
      <a
        href="https://drive.google.com/file/d/19kfq8NnqcerWO1lGxhS2S9rchQlYeTDB/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP2") }}
      </a>
    </div>

    <div class="link-container mb-3">
      <a
        href="https://drive.google.com/file/d/1MWtj9-I35LWURHw0irLza3FIkAT6hYuv/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP3") }}
      </a>
    </div>
    <div class="link-container mb-3">
      <a
        href="https://drive.google.com/file/d/1o0RYjM-MnjPPcxG5PUSlGSsuY7ET-jMk/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP4") }}
      </a>
    </div>

    <div class="link-container mb-3">
      <a
        href="https://drive.google.com/file/d/1FuUuQ5lVHQpA_Gsl2g4-7raorYnm2Ziz/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP5") }}
      </a>
    </div>
    <div class="link-container mb-3">
      <a
        href="https://drive.google.com/file/d/14MqalZjhuVWRRL114OJnA60rvIVlU3Yv/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP6") }}
      </a>
    </div>
    <div class="link-container mb-3">
      <a
        href="https://drive.google.com/file/d/14MqalZjhuVWRRL114OJnA60rvIVlU3Yv/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP7") }}
      </a>
    </div>
    <div class="link-container mb-3">
      <a
        href="https://docs.google.com/document/d/1_POVHmkCqyv072Fcuf1oqLQ08d0ot5Gl/edit?usp=sharing&ouid=106749311590716813947&rtpof=true&sd=true"
        class="link-item"
      >
        {{ $t("DocumentP8") }}
      </a>
    </div>
    <div class="link-container mb-3">
      <p class="p-0 m-0">
        {{ $t("DocumentP9") }}
      </p>
      <a
        href=" https://drive.google.com/file/d/14OtLIZdEZGAQ60Mx89vfnMPh_YePCKs1/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP10") }}
      </a>
      <a
        href="https://drive.google.com/file/d/1zlgOuP-xXgcsDwJ2KWQJgoTRXx8FckPt/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP11") }}
      </a>
      <a
        href="https://drive.google.com/file/d/1X08f0MgkGzQFyMLIOGz6HIkU3Cud9vX-/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP12") }}</a
      >
      <a
        href="https://drive.google.com/file/d/1dEjQX0PxP3LbtvE3-MpObo2Z7Y_ZLjwA/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP13") }}
      </a>
      <a
        href="https://drive.google.com/file/d/1YrjURjAh8k6a6GXMA3XnrgMhbr96J-p7/view?usp=sharing"
        class="link-item"
      >
        Жемқорлыққа қарсы нұсқаулық</a
      >
      <a
        href="https://drive.google.com/file/d/1nVgkTALsDDEqHqTeq8tmWITbpi5DqnOK/view?usp=sharing"
        class="link-item"
      >
        Әдеп кодексі</a
      >
      <a
        href="https://drive.google.com/file/d/1HVihex9PfjBt06n4AP7IFCAEeFU1G5oT/view?usp=sharing"
        class="link-item"
      >
        Жемқорлыққа қарсы комплеанс қызметін ұйым</a
      >
      <a
        href="https://drive.google.com/file/d/1CzmGIPcBh_j9znkSiyCPtmcs5Z-eoqUv/view?usp=sharing"
        class="link-item"
      >
        Сыбайлас жемқорлыққа қарсы стандарты</a
      >
      <a
        href="https://drive.google.com/file/d/1r4nOJU35d3iV1UpKGQQyANhGy2muCUmF/view?usp=sharing"
        class="link-item"
      >
        Корпоративно-этический кодекс</a
      >
      <a
        href="https://drive.google.com/file/d/1yLVYHGAHuHFKDwPKZAroAOGbaUZNernp/view?usp=sharing"
        class="link-item"
      >
        Сыбайлас жемқорлыққа қарсы саясаты</a
      >
      <a
        href="https://docs.google.com/document/d/1jWSGgsM7v2rrh7fW-7xA0ew18M5q6GS8/edit?usp=sharing&ouid=105621524366983933022&rtpof=true&sd=true"
        class="link-item"
      >
        Бұйрық
      </a>
      <a
        href="https://drive.google.com/file/d/1NmIfaa3p_p5NxihS7dXheYWeZp7Mf6ew/view?usp=sharing"
        class="link-item"
      >
        План меропртятий
      </a>
      <a
        href="https://drive.google.com/file/d/1x8qhIaymAo3Lol6i04xERQEbHcpvatK_/view?usp=sharing"
        class="link-item"
      >
        Рекомендации
      </a>
      <a
        href="https://docs.google.com/document/d/1fIpizk5cBrQ0u0MHEBF6_8BlcPCdoyYC/edit?usp=sharing&ouid=105621524366983933022&rtpof=true&sd=true"
        class="link-item"
      >
        Об утверждении Типового положения об антикоррупционных комплаенс-службах
        в субъектах квазигосударственного сектора
      </a>
    </div>
    <div class="link-container mb-3">
      <a
        href="https://drive.google.com/file/d/1tpbimZU4h1OCVcRlVQXd5ctmDe6KK_JS/view?usp=sharing"
        class="link-item"
      >
        {{ $t("DocumentP14") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.link-container {
  word-wrap: break-word;
}

.link-item {
  display: block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
